.container {
  margin: auto;
}
@media only screen and (max-width: 576px) {
  .container {
    width: 450px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  .container {
    width: 540px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .container {
    width: 720px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .container {
    width: 960px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .container {
    width: 1050px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .container {
    width: 1300px;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .container {
    width: 1400px;
  }
}
@media only screen and (min-width: 1800px) {
  .container {
    width: 1550px;
  }
}
