@import "../../../../colors/colors.scss";
.color-picker {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .darkLogo {
    padding: 70px 0px 30px 0px;
    margin: 0px 100px;
  }
  .uploadlogo {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 25px 0px 40px 0px;
    .profile {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: white;
      box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.1);
      margin: 0px 20px 0px 0px;
      img {
        cursor: auto;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        &.notUploaded {
          width: 36px;
        }
      }
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      text-decoration: underline;
      color: $primary;
      font-family: "Lato";
      cursor: pointer;
    }
  }

  .btnDashboard {
    width: 100%;
    margin-top: 30px;
  }
  img {
    cursor: pointer;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    font-family: "Metropolis";
    color: $primary;
  }
  .backword {
    position: absolute;
    left: 12%;
    top: 5%;
    cursor: pointer;
  }
}
//  -------------------------------- Color Picker -----------------------------------

.chrome-picker {
  background-color: #e5eeed !important;
  padding: 10px;
  border-radius: 10px !important;
  height: 14rem;
}
.hue-horizontal {
  border-radius: 20px;
}
.saturation-black {
  border-radius: 10px;
}
.saturation-white {
  border-radius: 10px;
}
.chrome-picker :first-child {
  border-radius: 10px;
}

// -----------------------------------------------

@media only screen and (max-width: 1200px) {
  .color-picker {
    padding: 20px 50px 50px 50px;
    border-radius: 15px;
    .darkLogo {
      padding: 30px 0px 20px 0px;
      img {
        width: 85px;
        height: 35px;
      }
    }
    .uploadlogo {
      .profile {
        width: 64px;
        height: 64px;
        background-size: 25px 24px;
        img {
          width: 19px;
          height: 18px;
        }
      }
      a {
        font-size: 14px;
        line-height: 16px;
      }
    }
    .picker {
      width: 230px;
      height: 190px;
      margin: 10px 0px 20px 0px;
    }
    p {
      font-size: 16px;
      line-height: 18px;
    }
    .backword {
      width: 23px;
      height: 23px;
    }
  }
  .chrome-picker {
    margin: 10px 0px 20px 0px;
  }
}

@media only screen and (max-width: 552px) {
  .color-picker {
    // padding: 20px 50px 50px 50px;
    // border-radius: 15px;
    // .darkLogo {
    //   padding: 30px 0px 20px 0px;
    //   img {
    //     width: 85px;
    //     height: 35px;
    //   }
    // }
    .uploadlogo {
      .profile {
        background-size: 20px 20px;
      }
    }
  }
}
