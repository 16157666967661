@import "../../../colors/colors.scss";
.UpdateRole {
  position: relative;
  padding: 25px;
  h6 {
    font-family: "Metropolis";
    font-weight: 600;
    font-size: 15px;
    line-height: 65px;
    letter-spacing: -0.01em;
    color: #000000;
    padding-top: 30px;
  }
  .input {
    margin-bottom: 20px;
    width: 100%;
    position: relative;
    .forErrors {
      color: red;
      font-size: 14px;
      font-family: "Metropolis";
      font-weight: 600;
      position: absolute;
      bottom: -38%;
      right: 0%;
      user-select: none;
    }
  }
  h5 {
    font-family: "Metropolis";
    font-weight: 500;
    font-size: 16px;
    line-height: 65px;
    letter-spacing: -0.01em;
    color: #000000;
    text-align: left;
  }
  .editRole {
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .roles {
      width: 120px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      p {
        font-family: "Metropolis";
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #595959;
        cursor: pointer;
      }
      &.selected {
        background: #d2dfdf;
      }
    }
    .roles:hover {
      background: #d2dfdf;
    }
  }
  .btnUpdate {
    padding: 30px 0px;
  }
  img {
    position: absolute;
    top: 5%;
    right: 4%;
    cursor: pointer;
  }
}
