@import "../../../colors/colors.scss";
.selectTests {
  background-color: #f8f8f8;
  .innerDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0px 50px;
    .btnDiv {
      display: none;
    }
    .remainingProgressDiv {
      align-self: center;
      width: 100%;
    }
    h6 {
      font-family: "Metropolis";
      font-weight: 500;
      font-size: 18px;
      color: #000000;
      margin-top: 60px;
      span {
        color: rgba(32, 124, 119, 1);
        cursor: pointer;
      }
      span:hover {
        color: rgba(32, 124, 119, 0.8);
      }
    }
    .totalTestSelected {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      .backArrowDiv {
        padding: 0px;
        width: 50px;
        height: 50px;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #012824;
        box-sizing: border-box;
        margin-right: 10px;
        img {
          width: 18px;
          height: 18px;
          margin: 0;
        }
        .rotate {
          transform: rotate(180deg);
        }
      }
      .empty {
        position: relative;
        width: 174px;
        min-width: 174px;
        height: 58px;
        border: 2px dashed rgba(4, 40, 38, 0.25);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        margin: 0px 10px;
        p {
          font-family: "Metropolis";
          font-size: 16px;
          color: rgba(4, 40, 38, 0.5);
        }
        img {
          cursor: pointer;
          width: 30px;
          height: 30px;
        }
        .premium {
          position: absolute;
          cursor: pointer;
          width: 30px;
          height: 30px;
          right: -7%;
          top: -25%;
        }
        &.selectedTests {
          background-color: #012824;
          padding: 0px 20px;
          p {
            color: white;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          img {
            cursor: pointer;
            width: 12px;
            height: 12px;
          }
        }
      }
      img {
        width: 48px;
        height: 48px;
        margin-left: 20px;
      }
      .arrowDiv {
        padding: 0px;
        width: 50px;
        height: 50px;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #012824;
        box-sizing: border-box;
        margin-left: 10px;
        img {
          width: 18px;
          height: 18px;
          margin: 0;
        }
      }
      .disabled {
        opacity: 0.5 !important;
      }
      .activeNext {
        cursor: pointer;
      }
    }
    h5 {
      font-family: "Lato";
      font-size: 26px;
      color: #012824;
      font-weight: 600;
      margin: 50px 0px 80px 0px;
    }
    .upperUI {
      padding: 0px 100px;
    }
    .design {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      .UIdiv {
        margin: 15px;
      }
    }
    .inputArea {
      display: flex;
      justify-content: space-between;
      margin: 80px 0px;
      .byTest {
        width: 35%;
        position: relative;
        img {
          position: absolute;
        }
      }
      .byJob {
        width: 23%;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .selectTests {
    .innerDiv {
      .btnDiv {
        display: block;
        margin: 60px 0px 0px 0px;
        display: flex;
        justify-content: center;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .selectTests {
    .innerDiv {
      h6 {
        text-align: center;
      }
      .totalTestSelected {
        .empty {
          width: 150px;
          min-width: 120px;
          .premium {
            width: 25px;
            height: 25px;
          }
          p {
            font-size: 14px;
          }
          img {
            width: 20px;
            height: 20px;
          }
        }
        .premiumTest {
          display: none;
        }
      }
      .upperUI {
        padding: 0px;
      }
      .inputArea {
        margin: 20px 0px;
        .byTest {
          width: 100%;
        }
        .byJob {
          display: none;
        }
      }
    }
  }
}
