@import "../../../colors/colors.scss";
.logos {
  display: flex;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  background-color: rgba(2, 27, 25, 1);
  align-items: center;
  justify-content: center;
  margin: 10px 10px;
  h6 {
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    color: white;
    text-align: center;
  }
  .mobSize {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .logos {
    width: 150px;
    height: 150px;
  }
}
@media only screen and (max-width: 992px) {
  .logos {
    width: 105px;
    height: 105px;

    h6 {
      font-size: 14px;
      line-height: 16px;
    }
    .tabSize {
      display: none;
    }
    .mobSize {
      display: block;
      img {
        object-fit: contain;
      }
    }
  }
}
