* {
  margin: 0;
  padding: 0;
}
@import "../../../colors/colors.scss";
.Assessments {
  background-color: #f8f8f8;
  h1 {
    text-align: center;
    font-family: "Metropolis";
    font-weight: 700;
    font-size: 34px;
    line-height: 50px;
    letter-spacing: 0.5px;
    padding: 80px 0px 20px 0px;
  }
  .p-text {
    display: flex;
    justify-content: center;
    p {
      text-align: center;
      font-family: "Metropolis";
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
  .Assess-block {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 80px 0px;
    .block-one {
      position: relative;
      width: 430px;
      height: 240px;
      background: #e5eeed;
      border-radius: 15px;
      .Role {
        padding-left: 10px;
      }
      h5 {
        font-family: "Metropolis";
        font-weight: 700;
        font-size: 18px;
        line-height: 100px;
        color: $primary;
        margin: 0px 60px;
      }
    }

    .developer {
      position: absolute;
      display: flex;
      text-align: left;
      align-items: center;
      background: #ffffff;
      width: 430px;
      height: 58.3px;
      box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
      border-radius: 8px;
      top: 30%;
      left: 17%;
      p {
        font-family: "Metropolis";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 65px;
        color: rgba(0, 0, 0, 0.65);
        margin: 0px 30px;
      }
    }
    .jobrole {
      background-color: white;
      position: absolute;
      width: 430px;
      height: 190px;
      border-radius: 15px;
      bottom: -50%;
      right: -22%;
      p {
        text-align: left;
        font-family: "Metropolis";
        font-weight: 600;
        font-size: 16px;
        line-height: 65px;
        color: rgba(0, 0, 0, 0.65);
        margin: 0px 30px;
      }
      .archi {
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          margin-right: 20px;
        }
      }
      .engineer {
        background-color: rgba(253, 245, 209, 1);
      }
    }
  }
  .select-test {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 100px 0px 40px 0px;
    .mobheadings {
      display: none;
    }
  }
  .questions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px 0px 80px 0px;
    .reverse {
      display: none;
    }
    .mobheadings {
      display: none;
    }
  }
  .textdiv {
    display: flex;
    img {
      margin: 0px 30px;
    }
    h6 {
      font-family: "Metropolis";
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.5px;
      margin-bottom: 20px;
    }
    p {
      text-align: left;
      font-family: "Metropolis";
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .Assessments {
    h1 {
      font-size: 20px;
      line-height: 50px;
      padding-bottom: 0px;
    }
    .p-text {
      flex-direction: column;
      p {
        font-size: 14px;
        line-height: 16px;
      }
    }
    .Assess-block {
      flex-direction: column;
      padding: 30px 0px;
      .job {
        display: block;
        margin-top: 30px;
      }
    }
    .select-test {
      padding: 0;
      flex-direction: column;
      .uiDesign {
        width: 321px;
        height: 193px;
        margin-top: 30px;
      }
      .dextopHeadings {
        display: none;
      }
      .mobheadings {
        display: block;
      }
    }
    .questions {
      flex-direction: column;
      padding-top: 30px;
      .selector {
        display: none;
      }
      .reverse {
        display: block;
        margin-top: 30px;
        width: 348px;
        height: 200px;
      }
      .dextopHeadings {
        display: none;
      }
      .mobheadings {
        display: block;
      }
    }
    .textdiv {
      flex-direction: column;
      text-align: center;
      img {
        width: 45px;
        height: 47px;
        margin-bottom: 10px;
      }
      h6 {
        font-size: 16px;
        line-height: 19px;
      }
      p {
        text-align: center;
        font-size: 13px;
        line-height: 15px;
      }
    }
  }
}
