.logout {
  position: relative;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  h1 {
    text-align: center;
  }
  .btnLogout {
    width: 30%;
    margin: 30px auto 0px auto;
  }
  img {
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 20px;
  }
}
