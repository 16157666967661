@import "../../../colors/colors.scss";
.backPage {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(1, 40, 36, 1);
  position: relative;
  padding: 20px 0px;
  img {
    position: absolute;
    left: 7%;
    cursor: pointer;
  }
  h5 {
    font-family: "Metropolis";
    font-weight: 600;
    font-size: 22px;
    color: #ffffff;
  }
}
