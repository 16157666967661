@import "../../../colors/colors.scss";
.Start {
  .btnDiv {
    display: none;
  }
  .heading {
    text-align: center;
    display: block;
    h1 {
      font-weight: 700;
      font-size: 42px;
      line-height: 48px;
      letter-spacing: 0.01em;
      color: #012824;
      font-family: "Lato";
      padding: 60px 0px 30px 0px;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.75);
      font-family: "Metropolis";
    }
  }
  .assessmentDiv {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 50px 0px 30px 0px;
  }
  .largeBtn {
    width: 30%;
    margin: 0px auto 30px auto;
  }
  .disabled {
    opacity: 0.5;
  }
}
@media only screen and (max-width: 1200px) {
  .Start {
    .btnDiv {
      display: block;
      margin: 60px 0px 0px 0px;
      display: flex;
      justify-content: center;
    }
    .heading {
      h1 {
        font-size: 35px;
      }
      p {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .Start {
    .largeBtn {
      width: 100%;
    }
  }
}
