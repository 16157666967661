@import "../../../colors/colors.scss";
.NoCandidate {
  background-color: #f8f8f8;
  padding: 50px 0px 0px 0px;
  .innerDiv {
    padding: 0px 60px;
    .previousPage {
      padding: 40px 0px;
      img {
        cursor: pointer;
      }
    }
    .pageinationDiv {
      margin: 30px 0px 70px 0px;
    }
    .btnDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 50px;
      margin-bottom: 20px;
      h3 {
        font-family: "Metropolis";
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.75);
      }
    }
    .testTableDiv {
      margin-bottom: 30px;
    }
  }
  .noCandidates {
    padding: 40px 0px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(1, 40, 36, 0.15);
    border-radius: 0px 0px 10px 10px;
    p {
      font-family: "Metropolis";
      font-weight: 400;
      font-size: 16px;
      line-height: 15px;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
