@import "../../../colors/colors.scss";
.DashboardNav {
  background-color: #f8f8f8;
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0px;
    .logoDiv {
      cursor: pointer;
    }
    .dashBtn {
      display: flex;
    }
    .otherOptions {
      display: flex;
      align-items: center;
      img {
        margin-right: 15px;
        cursor: pointer;
      }
    }
    .dashbordMoreMenu {
      display: none;
    }
    .dd-wrapper {
      font-size: 16px;
      font-family: "Metropolis";
      .dd-header {
        border-radius: 8px;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
      }
    }
    .dd-wrapper .dd-list {
      background-color: transparent;
      border: none;
    }
    .dd-wrapper .dd-list .dd-scroll-list {
      padding: 0px 15px;
      max-height: 280px;
      background-color: white;
      overflow-y: auto;
      border-radius: 8px;
      margin-top: 15px;
      span {
        display: none;
      }
    }
    .dd-wrapper .dd-list .dd-scroll-list .dd-list-item {
      font-size: 16px;
      font-weight: 400;
      font-family: "Metropolis";
      color: rgba(0, 0, 0, 0.8);
      margin: 0;
    }
    .dd-wrapper .dd-list .dd-scroll-list .dd-list-item:hover {
      background-color: white;
      font-size: 16.5px;
      font-weight: 500;
      color: black;
      transition-duration: 0.09s;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .DashboardNav {
    nav {
      .dashbordMoreMenu {
        display: block;
        cursor: pointer;
      }

      .dashBtn {
        display: none;
      }
      .otherOptions {
        display: none;
      }
    }
  }
  .dextopMobNav {
    .dashboardNavOptions {
      display: flex;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      padding: 15px 0px;
      cursor: pointer;

      p {
        font-family: "Metropolis";
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.65);
      }
      img {
        margin: 0px 20px 0px 30px;
      }
    }
    .dashboardNavOptions:hover {
      background-color: rgb(241, 241, 241);
    }
  }
}
