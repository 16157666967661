@import "../../../colors/colors.scss";
.Notification {
  background-color: #f4f7f7;
  display: flex;
  align-items: center;
  border-radius: 10px;
  img {
    margin: 0px 0px 0px 30px;
  }
  p {
    font-family: "Metropolis";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.9);
    padding: 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .Notification {
    flex-direction: column;
    height: fit-content;
    img {
      margin: 15px 20px 0px 30px;
    }
    p {
      padding: 20px 30px;
    }
  }
}
@media only screen and (max-width: 992px) {
  .Notification {
    img {
      width: 20px;
      height: 20px;
    }
    p {
      font-size: 13px;
      padding-top: 10px;
    }
  }
}
