@import "../../../colors/colors.scss";
.ProgressDiv {
  width: 50%;
  min-width: 880px;
  height: 155px;
  background: rgba(229, 238, 237, 0.75);
  border-radius: 20px;
  margin: 80px auto 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    color: rgba(4, 40, 38, 1);
    font-family: "Lato";
    font-size: 15px;
    font-weight: 600;
  }
  .circlesDiv {
    display: flex;
    align-items: center;
    .verticalForMobile {
      display: none;
    }
    .inProgress {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: white;
      border: 10px solid rgba(4, 40, 38, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        width: 16px;
        height: 14px;
      }
    }
    .upComing {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background: rgba(4, 40, 38, 0.95);
      position: relative;
    }
    .complete {
      background-color: rgba(4, 40, 38, 1);
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: 10px solid rgba(4, 40, 38, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .assessment {
      p {
        position: absolute;
        bottom: -41px;
        left: -45px;
      }
    }
    .selectTest {
      p {
        position: absolute;
        bottom: -41px;
        left: -30px;
      }
    }
    .modify {
      p {
        position: absolute;
        bottom: -41px;
        left: -30px;
      }
    }
    .reviewAssessment {
      p {
        position: absolute;
        bottom: -41px;
        left: -18px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .ProgressDiv {
    height: auto;
    width: 90%;
    min-width: 90%;
    justify-content: flex-start;
    padding-left: 40px;
    margin: 40px auto 0px auto;
    box-sizing: border-box;
    .circlesDiv {
      flex-direction: column;
      padding: 30px 0px;
      .inProgress {
        width: 10px;
        height: 10px;
        border: 5px solid rgba(4, 40, 38, 1);
        img {
          height: 7px;
        }
      }
      .upComing {
        width: 7px;
        height: 7px;
      }
      .complete {
        width: 10px;
        height: 10px;
        border: 5px solid rgba(4, 40, 38, 1);
        img {
          height: 7px;
        }
      }
      .verticalForMobile {
        display: block;
      }
      .forDeskTop {
        display: none;
      }
      .assessment {
        p {
          bottom: -3px;
          left: 45px;
        }
      }
      .selectTest {
        p {
          bottom: -3px;
          left: 45px;
        }
      }
      .modify {
        p {
          bottom: -3px;
          left: 45px;
        }
      }
      .reviewAssessment {
        p {
          bottom: -3px;
          left: 45px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ProgressDiv {
    .circlesDiv {
      width: 40px;
    }
  }
}
