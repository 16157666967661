@import "../../../colors/colors.scss";
.SignupScreen {
  position: relative;
  background-color: #c5d7d7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0px;
  box-sizing: border-box;
  overflow-x: hidden;
  .container {
    display: flex;
    justify-content: center;
    .signupLeftDiv {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $primary;
      border-radius: 15px 0px 0px 15px;
      width: 800px;
      min-width: 700px;
      .page {
        position: absolute;
        color: rgba(255, 255, 255, 0.8);
        font-family: "Metropolis";
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        right: 6%;
        bottom: 4%;
        span {
          color: white;
          font-size: 22px;
        }
      }
    }
    .signupRightDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 810px;
      width: 500px;
      background: #ffffff;
      border-radius: 0px 15px 15px 0px;
      box-sizing: border-box;
      padding: 20px 60px;
    }
  }
  .circleOne {
    box-sizing: border-box;
    position: absolute;
    border-radius: 50%;
    width: 691px;
    height: 691px;
    left: -1%;
    top: -42%;
    border: 100px solid rgba(229, 238, 237, 0.03);
  }
  .circleTwo {
    box-sizing: border-box;
    position: absolute;
    width: 598px;
    height: 598px;
    border-radius: 50%;
    right: -18%;
    top: -30%;
    border: 150px solid rgba(4, 40, 38, 0.025);
  }
}

@media only screen and (max-width: 1200px) {
  .SignupScreen {
    padding: 30px 0px;
    height: 100vh;
    .signupLeftDiv {
      display: none !important;
    }
    .signupRightDiv {
      height: 720px !important;
      border-radius: 10px !important;
      position: relative;
    }
    .circleOne {
      display: none;
    }
    .circleTwo {
      display: none;
    }
  }
}

@media only screen and (max-width: 552px) {
  .SignupScreen {
    .signupRightDiv {
      height: 630px !important;
      width: 400px !important;
      padding: 20px 30px !important;
    }
  }
}
