.Management {
  display: flex;
  flex-direction: column;
  width: 100%;
  .btnDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    h5 {
      font-family: "Metropolis";
      font-weight: 500;
      font-size: 18px;
      line-height: 65px;
      letter-spacing: -0.01em;
      color: #000000;
    }
  }
}

@media only screen and (max-width: 992px) {
  .Management {
    .btnDiv {
      .btnNewRole {
        width: 100%;
      }
      h5 {
        display: none;
      }
    }
  }
}
