@import "../../../colors/colors.scss";
.test {
  background-color: $primary;
  width: 100%;
  padding: 98px 0px 149px 0px;
  .mobHeading {
    display: none;
  }
  h2 {
    text-align: center;
    font-family: "Lato";
    color: white;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
  }
  .mobText {
    display: none;
  }
  p {
    display: none;
  }
  .testMain {
    display: flex;
    justify-content: space-between;
    padding: 90px 22px 20px 22px;
    align-items: center;
    .btnDiv {
      margin-right: 30px;

      .testContent {
        line-height: 25px;
      }
      h5 {
        font-family: "Metropolis";
        color: rgba(255, 255, 255, 0.8);
        font-size: 19px;
        font-weight: 400;
        margin: 18px 0px 0px 0px;
      }

      .btnTest {
        margin-top: 62px;
      }
    }
    .logosDiv {
      display: flex;
    }
  }
  .btnStart {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .test {
    padding: 70px 0px 50px 0px;
    h2 {
      font-size: 30px;
      line-height: 31px;
      margin: 20px 0px;
    }
    p {
      display: block;
      font-family: "Metropolis";
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
      margin: 40px 0px 0px 0px;
    }
    .testMain {
      padding: 30px 0px 20px 0px;
      .btnDiv {
        display: none;
      }
    }
  }
  // .react{
  //   width: 64px;
  //   height: 57px
  // }
  // .flutter{
  //   width: 51px;
  //   height: 52px;
  // }
  // .bry{
  //   width: 52px;
  //   height: 45px;
  // }
  // .cube{
  //   width: 49px;
  //   height: 49px;
  // }
  // .azure{
  //   width: 52px;
  //   height: 61px;
  // }
  // .laravel{
  //   width: 49px;
  //   height: 55px;
  // }
  // .twoCurves{
  //   width: 55px;
  //   height: 41px;
  // }
  // .tf{
  //   width: 48px;
  //   height: 49px;
  // }
}

@media only screen and (max-width: 992px) {
  .test {
    .tabHeading {
      display: none;
    }
    .mobHeading {
      display: block;
    }
    h2 {
      font-size: 22px;
      line-height: 24px;
    }
    .tabText {
      display: none;
    }
    .mobText {
      display: block;
    }
    p {
      font-size: 15px;
      line-height: 18px;
    }
    .btnStart {
      display: block;
      display: flex;
      justify-content: center;
      margin-top: 35px;
    }
    .logosDiv {
      .mobResponsive {
        display: none;
      }
    }
  }
  // .react{
  //   width: 64px;
  //   height: 57px
  // }
  // .flutter{
  //   width: 51px;
  //   height: 52px;
  // }
  // .bry{
  //   width: 52px;
  //   height: 45px;
  // }
  // .cube{
  //   width: 49px;
  //   height: 49px;
  // }
  // .azure{
  //   width: 52px;
  //   height: 61px;
  // }
  // .laravel{
  //   width: 49px;
  //   height: 55px;
  // }
  // .twoCurves{
  //   width: 55px;
  //   height: 41px;
  // }
  // .tf{
  //   width: 48px;
  //   height: 49px;
  // }
}
