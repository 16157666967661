@import "../../../colors/colors.scss";
button {
  border-radius: 30px;
  color: #012824;
  cursor: pointer;
  border: none;
  padding: 15px 45px;
  font-weight: 700;
  font-size: 16px;
  font-family: "Lato";
  background-color: white;
  img {
    margin-left: 20px;
  }

  &.outline {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    margin-right: 5px;
    font-weight: 400;
    padding: 15px 40px;
    &.outline:hover {
      color: white;
      background-color: transparent;
      opacity: 0.85;
      transition-duration: 0.5s;
    }
  }
  &.Darkoutline {
    background-color: transparent;
    border: 1.5px solid $primary;
    color: $primary;
    margin-right: 5px;
    font-weight: 400;
    padding: 15px 40px;
    &.Darkoutline:hover {
      color: $primary;
      background-color: transparent;
      opacity: 0.85;
      transition-duration: 0.5s;
    }
  }
  &.dark {
    color: white;
    background-color: rgba(4, 40, 38, 1);
    font-weight: 500;
    &.dark:hover {
      color: $primary;
      background-color: rgba(4, 40, 38, 0.2);
      transition-duration: 0.3s;
    }
  }
  &.large {
    color: white;
    background-color: rgba(4, 40, 38, 1);
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    &.large:hover {
      color: $primary;
      background-color: rgba(4, 40, 38, 0.4);
      transition-duration: 0.3s;
    }
  }
  &.largeOutline {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 2px solid #1976d2;
    border-radius: 5px;
    width: 100%;
    color: #1976d2;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    cursor: pointer;
    padding: 10px 0px;
    height: 51px;
    &.largeOutline:hover {
      opacity: 0.8;
      transition-duration: 0.5s;
    }
  }
  &.largeLight {
    font-weight: 400;
    color: $primary;
    background-color: rgba(253, 245, 209, 1);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 100%;
    line-height: 16px;
    padding: 12px 32px;
    &.largeLight:hover {
      background-color: rgba(253, 245, 209, 0.85);
      transition-duration: 0.5s;
    }
  }
  &.homeLargeLight {
    font-weight: 400;
    color: $primary;
    background-color: rgba(253, 245, 209, 1);
    border-radius: 5px;
    margin-top: 15px;
    width: 100%;
    line-height: 16px;
    padding: 15px 32px;
    &.homeLargeLight:hover {
      background-color: rgba(253, 245, 209, 0.85);
      transition-duration: 0.5s;
    }
  }
  &.blue {
    color: white;
    background-color: rgba(0, 0, 255, 0.9);
    border-radius: 5px;
    &.blue:hover {
      color: white;
      background-color: rgba(0, 0, 255, 0.7);
      transition-duration: 0.3s;
    }
  }
  &.smallBorder {
    color: white;
    font-weight: 500;
    background-color: rgba(4, 40, 38, 1);
    border-radius: 5px;
    &.smallBorder:hover {
      color: $primary;
      background-color: rgba(4, 40, 38, 0.2);
      transition-duration: 0.3s;
    }
  }
  &.smallDull {
    color: $primary;
    background-color: rgba(4, 40, 38, 0.2);
    border-radius: 5px;
    &.smallDull:hover {
      background-color: rgba(4, 40, 38, 0.4);
    }
  }

  &.leftBorder {
    background-color: rgba(4, 40, 38, 1);
    border-radius: 10px 0px 0px 10px;
    color: white;
    font-weight: 400;
    &.leftBorder:hover {
      color: white;
      background-color: rgba(4, 40, 38, 1);
    }
  }
  &.basicLeft {
    background: #f2f4f4;
    border-radius: 10px 0px 0px 10px;
    color: $primary;
  }

  &.rightBorder {
    background-color: rgba(4, 40, 38, 1);
    color: white;
    font-weight: 400;
    border-radius: 0px 10px 10px 0px;

    &.rightBorder:hover {
      background-color: rgba(4, 40, 38, 1);
      color: white;
    }
  }
  &.basicRight {
    background: #f2f4f4;
    border-radius: 0px 10px 10px 0px;
    color: $primary;
  }

  &.small {
    padding: 12px 15px;
    border-radius: 5px;
    font-size: 15px;
    &.small:hover {
      background-color: rgba(4, 40, 38, 0.8);
      color: white;
    }
  }
  &.darkBorder {
    width: 100%;
    border-radius: 5px;
    border: 2px solid rgba(1, 40, 36, 1);
    &.darkBorder:hover {
      opacity: 0.7;
    }
  }
  &.Delete {
    color: white;
    background-color: #dc3545;
    border: none;
    border-radius: 5px;
    font-weight: 400;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    &.Delete:hover {
      opacity: 0.7;
      background-color: #dc3545;
    }
  }
}
button:hover {
  background-color: rgb(255, 255, 255, 0.8);
  transition-duration: 0.5s;
}

@media only screen and (max-width: 768px) {
  button {
    &.small {
      padding: 10px 14px;
      font-size: 12px;
      background: #e5eeed;
    }
  }
}
