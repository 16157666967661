@import "../../../colors/colors.scss";
.assessmentProgress {
  width: 150px;
  height: 32px;
  background: #d1e7dd;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-family: "Metropolis";
    font-weight: 500;
    font-size: 14px;
    color: $primary;
  }
}
.assessmentDetail {
  cursor: pointer;
}
.gelpCx {
  border-radius: 10px !important;
}
.bnAwAJ {
  justify-content: flex-start !important;
  padding: 0px 0px 0px 40px;
}
.bQdYbR {
  font-size: 14px;
}
.kVrXuC {
  font-family: "Metropolis";
}
.iSAVrt {
  font-family: "Lato";
  font-weight: 600;
}
