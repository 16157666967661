@import "../../../colors/colors.scss";
.ResetPassword {
  position: relative;
  background-color: #c5d7d7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 40px 0px;
  box-sizing: border-box;
  overflow-x: hidden;
  .container {
    display: flex;
    justify-content: center;
    .resetLeftDiv {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $primary;
      border-radius: 15px 0px 0px 15px;
      width: 800px;
      min-width: 700px;
      height: 85vh;
      min-height: 700px;
    }
    .resetFormDiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 85vh;
      min-height: 700px;
      width: 500px;
      min-width: 500px;
      background: #ffffff;
      border-radius: 0px 15px 15px 0px;
      box-sizing: border-box;
      padding: 20px 60px;
      text-align: center;
      h1 {
        font-family: "Metropolis";
        font-size: 36px;
        text-align: center;
        letter-spacing: -0.01em;
        color: #000000;
        width: 70%;
        margin: 0px auto 30px auto;
      }
      p {
        font-family: "Lato";
        font-size: 18px;
        line-height: 21px;
        color: $primary;
        margin-bottom: 30px;
      }
      .inputPassword {
        margin-bottom: 25px;
        position: relative;
        .forError {
          position: absolute;
          color: red;
          font-family: "Lato";
        }
      }
      .resendLink {
        p {
          text-align: left;
          margin-bottom: 20px;
          cursor: pointer;
          width: 27%;
        }
      }
    }
  }
  .gradient {
    box-sizing: border-box;
    position: absolute;
    width: 598px;
    height: 598px;
    border: 150px solid rgba(4, 40, 38, 0.025);
    border-radius: 50%;
    top: -30%;
    right: -10%;
  }
  .secondGradient {
    box-sizing: border-box;
    position: absolute;
    width: 726px;
    height: 726px;
    border-radius: 50%;
    border: 150px solid rgba(4, 40, 38, 0.025);
    bottom: -30%;
    left: -10%;
  }
}
@media only screen and (max-width: 1200px) {
  .ResetPassword {
    .container {
      .resetLeftDiv {
        display: none;
      }
      .resetFormDiv {
        border-radius: 15px;
      }
    }
    .gradient {
      display: none;
    }
    .secondGradient {
      display: none;
    }
  }
}
@media only screen and (max-width: 767px) {
  .ResetPassword {
    .container {
      .resetFormDiv {
        height: 50vh;
        min-height: 500px;
        h1 {
          font-size: 28px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
}
@media only screen and (max-width: 552px) {
  .ResetPassword {
    .container {
      .resetFormDiv {
        width: 350px;
        min-width: 0px;
        padding: 20px 35px;
        h1 {
          font-size: 24px;
        }
      }
    }
  }
}
