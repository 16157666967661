@import "../../../colors/colors.scss";
.topTalentWrapper {
  position: relative;

  .numImage {
    position: absolute;
    top: -28%;
    left: -17%;
    width: 100px;
    height: 100px;
  }
  .topTalent {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 20;
    border-radius: 15px;
    background-color: #c5d7d7;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 170px;
    width: 504px;
    box-sizing: border-box;
    padding: 0px 20px;

    h3 {
      font-family: "Lato";
      font-size: 24px;
      font-weight: 600;
      color: $primary;
      margin: 0;
    }
    h6 {
      font-family: "Metropolis";
      font-size: 16px;
      font-weight: 400;
      margin: 0px;
      color: rgba(0, 0, 0, 0.65);
      margin-top: 15px;
    }
    p {
      display: none;
    }
  }
}

@media only screen and (max-width: 1400px){
  .top-talent-cards-wrapper{
  .topTalentWrapper {
    .topTalent {
     
      width: 405px;
      
    }
  
}
}
}


@media only screen and (max-width: 1200px) {
  .top-talent-cards-wrapper{
    .topTalentWrapper{
      img {
        width: 40px;
        height: 43px;
        left: -7%;
        top: -11%;
      }
  .topTalent {
    width: 324px;
    height: 163px;
    padding: 25px 40px;
    h3 {
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
    }
    h6 {
      text-align: center;
      font-family: Lato;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;

    }
    
    
  
    
  }
}
}
}


// @media only screen and (max-width: 400px) {
//   .topTalent {
//     margin-bottom: 20px;
//     width: 350px;
//     height: 130px;
//     padding: 10px;
//     img {
//       width: 36px;
//       height: 38px;
//       top: 10%;
//       left: 7%;
//     }
//     h3 {
//       padding-top: 5px;
//       font-size: 16px;
//       line-height: 18px;
//     }
//     .tabText {
//       display: none;
//     }
//     .mobText {
//       display: block;
//       p {
//         font-family: "Metrolopis";
//         font-size: 14px;
//         line-height: 15px;
//         margin: 10px 0px 0px 0px;
//       }
//     }
//   }
// }

