@import "../../../colors/colors.scss";
.Library {
  text-align: center;
  padding: 70px 0px;
  background-color: #f8f8f8;
  .heading {
    display: flex;
    flex-direction: column;
    h1 {
      font-weight: 700;
      font-size: 36px;
      line-height: 38px;
      font-family: "Lato";
    }
    p {
      font-family: "Metropolis";
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      padding-top: 15px;
    }
    .mobText {
      display: none;
    }
  }
  .upperUI {
    padding: 0px 160px;
  }
  .design {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
  }
  .recommend {
    h5 {
      font-size: 26px;
      line-height: 30px;
      margin: 40px 0px 70px 0px;
      text-align: left;
      color: rgba(1, 40, 36, 1);
      font-family: "Lato";
    }
    h6 {
      display: none;
    }
  }
  .inputArea {
    display: flex;
    justify-content: space-between;
    margin: 80px 0px;
    .byTest {
      width: 35%;
      position: relative;
      img {
        position: absolute;
      }
    }
    .byJob {
      width: 23%;
    }
  }
}

@media only screen and (max-width: 992px) {
  .Library {
    .inputArea {
      flex-direction: column;
      margin: 20px 0px;
      .byTest {
        width: 100%;
        margin-bottom: 10px;
      }
      .byJob {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Library {
    .heading {
      h1 {
        font-size: 22px;
        line-height: 23px;
      }
      .mainText {
        display: none;
      }
      p {
        font-size: 15px;
        line-height: 19px;
      }
      .mobText {
        display: block;
        margin: 30px 0px 20px 0px;
      }
    }
    .upperUI {
      padding: 0px 30px;
    }
    .recommend {
      h5 {
        display: none;
      }
      h6 {
        display: block;
        font-family: "Metropolis";
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: rgba(1, 40, 36, 0.85);
        margin: 15px 0px;
      }
    }
  }
  .white {
    width: 95% !important;
  }
}
