@import "../../../colors/colors.scss";
.sendMail {
  background: rgba(197, 215, 215, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  p {
    font-family: "Metropolis";
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    span {
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
@media only screen and (max-width: 992px) {
  .sendMail {
    p {
      font-size: 14px;
    }
  }
}
@media only screen and (max-width: 767px) {
  .sendMail {
    p {
      width: 72%;
      text-align: center;
      font-size: 12px;
    }
  }
}
