@import "../../../colors/colors.scss";
.CandidateGetStarted {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c5d7d7;
  padding: 70px 0px;
  .remember {
    padding: 40px 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    img {
      margin: 30px 0px;
    }
    p {
      margin: 10px auto;
      font-family: "Metropolis";
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.85);
      width: 80%;
      span {
        font-weight: 600;
        font-size: 20px;
      }
    }

    h5 {
      font-family: "Metropolis";
      font-weight: 600;
      font-size: 19px;
      line-height: 20px;
      color: #000000;
      margin: 40px auto;
      width: 80%;
    }
    ul {
      padding: 0px 50px;
      li {
        font-family: "Metropolis";
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 15px;
        width: 87%;
        margin: 10px auto;
      }
    }
    .btnStarted {
      width: 30%;
      margin: 40px auto 50px auto;
    }
  }
  span {
    font-weight: 600;
    font-size: 17px;
  }
}

@media screen and (max-width: 800px) {
  .CandidateGetStarted {
    padding: 0px;

    .remember {
      .btnStarted {
        width: auto;
      }
    }
  }
}
