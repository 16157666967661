* {
  margin: 0;
  padding: 0;
}
@import "../../../colors/colors.scss";
.Hire {
  background-color: #f8f8f8;
  text-align: center;
  .hireHeading {
    display: flex;
    justify-content: center;
    h1 {
      font-family: "Metropolis";
      font-weight: 700;
      font-size: 34px;
      line-height: 50px;
      letter-spacing: 0.5px;
      padding: 60px 0px 30px 0px;
      margin-right: 5px;
    }
  }
  .mobText {
    display: none;
  }
  p {
    font-family: "Metropolis";
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: rgba(0, 0, 0, 0.65);
  }
  .perfomance {
    display: flex;
    justify-content: space-around;
    .scores {
      padding: 150px 0px 50px 0px;
    }
    .table {
      object-fit: contain;
      padding: 0px 0px 130px 0px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .Hire {
    .hireHeading {
      flex-direction: column;
      padding: 60px 0px 30px 0px;
      h1 {
        font-size: 22px;
        line-height: 25px;
        padding: 0;
      }
    }
    .dextopText {
      display: none;
    }
    .mobText {
      display: block;
      p {
        font-size: 15px;
        line-height: 15px;
      }
    }
    .perfomance {
      .scores {
        padding: 50px 0px;
        width: 256px;
        height: 147px;
      }
      .table {
        display: none;
      }
    }
  }
}
