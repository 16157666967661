@import "../../../colors/colors.scss";
.previousPage {
  padding: 40px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    cursor: pointer;
  }
  .statusDiv {
    width: 15%;
    height: 40px;
    outline: none;
    border: none;
    padding: 0px 15px;
    border-radius: 5px;
    font-family: "Metropolis";
    &.completed {
      background-color: rgba(209, 231, 221, 1);
    }
    &.progress {
      background-color: rgba(248, 215, 218, 1);
    }
    &.invited {
      background-color: rgba(255, 243, 205, 1);
    }
  }
  .Hired {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: #d1e7dd;
    width: 170px;
    height: 40px;
    padding: 0px 20px;
    p {
      font-family: "Metropolis";
      font-weight: 500;
      font-size: 12px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
