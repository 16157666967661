@import "../../../colors/colors.scss";
.Company {
  display: flex;
  font-family: "Metropolis";
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-top: 20px;
  .updateLogo {
    display: flex;
    flex-direction: column;
    flex: 1;
    .companyName {
      position: relative;
      width: 70%;
      .forErrors {
        position: absolute;
        color: red;
        font-size: 14px;
        font-weight: 600;
        font-family: "Lato";
        right: 2%;
        top: 3%;
      }
    }
    .LogoDiv {
      display: flex;
      align-items: center;
      position: relative;
      .logo {
        margin: 20px 20px 20px 0px;
        border-radius: 100%;
      }
      h6 {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-decoration: underline;
        color: $primary;
        cursor: pointer;
      }
      p {
        color: #fb7878;
        font-size: 14px;
        line-height: 17px;
        cursor: pointer;
        width: fit-content;
      }
      .container-input-field-upload {
        width: auto !important;
        cursor: pointer !important;
        .input-field-upload {
          opacity: 0;
          position: absolute;
        }
      }
      .input-field-upload-error {
        position: absolute;
        color: red;
        font-size: 14px;
        font-weight: 600;
        font-family: "Lato";
        top: 20px !important;
      }
    }
  }
  .btnUpdate {
    width: 70%;
    margin-top: 50px;
  }

  .image {
    img {
      padding-right: 40px;
    }
  }
  // ------------------- Crome Picker ------------------

  .colorPicker {
    margin-top: 20px;
    .error-message {
      position: relative;
      color: red;
      font-size: 14px;
      font-weight: 600;
      font-family: "Lato";
      margin-left: 5px;
      margin-bottom: 5px;
    }
  }

  .chrome-picker {
    background-color: #e5eeed !important;
    padding: 10px;
    border-radius: 10px !important;
    height: 14rem;
  }
  .hue-horizontal {
    border-radius: 20px;
  }
  .saturation-black {
    border-radius: 10px;
  }
  .saturation-white {
    border-radius: 10px;
  }
  .chrome-picker :first-child {
    border-radius: 10px;
  }
}
// ------------------- Crome Picker ------------------

@media only screen and (max-width: 1400px) {
  .Company {
    .updateLogo {
      .companyName {
        width: 120%;
      }
    }
    .image {
      img {
        width: 400px;
        height: 400px;
        padding: 0px 0px 0px 70px;
      }
    }
    .btnUpdate {
      width: 120%;
    }
  }
}
@media only screen and (max-width: 1200px) {
  .Company {
    .updateLogo {
      margin-left: 15px;
    }
    .image {
      margin-left: 100px;
    }
  }
}
@media only screen and (max-width: 992px) {
  .Company {
    .updateLogo {
      align-items: center;
    }
    .image {
      display: none;
    }
  }
}
