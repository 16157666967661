@import "../../../colors/colors.scss";
.reinvent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #e5eeed;
  padding: 100px 0px;
  h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 38px;
    font-family: "Lato";
  }
  p {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    font-family: "Metropolis";
    padding: 30px 0px 40px 0px;
  }
}
@media screen and (max-width: 768px) {
  .reinvent {
    display: none;
  }
}
