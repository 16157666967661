@import "../../../colors/colors.scss";
.testType {
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
}
.testTime {
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
}
.details {
  h6 {
    font-family: "Metropolis";
    font-weight: 500;
    font-size: 14px;
    text-decoration-line: underline;
    color: #3f5c59;
    cursor: pointer;
  }
}
.delete {
  img {
    cursor: pointer;
  }
}

.gelpCx {
  border-radius: 10px !important;
}
.bnAwAJ {
  justify-content: flex-start !important;
  padding: 0px 0px 0px 40px;
}
