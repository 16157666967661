.customQuestionsTable {
  padding-bottom: 60px;
  .CustomTable {
    font-family: "Metropolis";
    background-color: white;
    border-radius: 5px 5px 10px 10px;
    padding: 0px 30px;
    box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
    table {
      width: 100%;
    }
    th {
      text-align: left;
      padding: 20px 0px;
      font-weight: 600;
      font-size: 15px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.75);
    }
    td {
      text-align: left;
      padding: 20px 0px;
      font-weight: 400;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.6);
      border-top: 1px solid rgba(1, 40, 36, 0.15);
    }
  }
}
