@import "../../../colors/colors.scss";
.easyHiring {
  background-color: #e5eeed;
  width: 100%;
  padding-bottom: 50px;
  h2 {
    text-align: center;
    color: #193b37;
    font-family: "Lato";
    font-size: 36px;
    font-weight: 700;
    padding: 80px 0px 30px 0px;
  }
  .main {
    display: flex;
    background-color: transparent;
    justify-content: space-around;
    align-items: center;
    h5 {
      color: $primary;
      line-height: 27px;
      font-family: "Metropolis";
      font-size: 19px;
      font-weight: 400;
      margin-bottom: 30px;
    }
    .btnStarted {
      margin-top: 40px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .easyHiring {
    h2 {
      font-size: 22px;
      line-height: 24px;
      padding: 70px 0px 30px 0px;
    }
    .main {
      flex-direction: column;
      text-align: center;
      h5 {
        text-align: left;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 15px;
      }
      img {
        width: 310px;
        height: 260px;
        margin-top: 70px;
      }
    }
  }
  .group {
    width: 319px;
    height: 265px;
  }
}
