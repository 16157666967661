.invite-wrapper {
    width: 100%;
    height: 850px;
    background: #FDF5D1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.invite-heading {
    width: 532px;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 50px;
    letter-spacing: 0.5px;
    color: #000000;
    padding-top: 50px;
    padding-bottom: 10px;
}

.invite-text {
    width: 610px;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 125%;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
}

.invite-img {
    position: absolute;
    bottom: 0;
}

@media (max-width:768px) {
    .invite-heading {
        font-size: 22px;
        width:100%;
        text-align: center
    }
    .invite-text {
        font-size: 14px;
        width:80%;
        text-align: center
    }
    .invite-img {
        width:100%;
    }
.invite-heading {
    padding-top: 50px;
}
    
}