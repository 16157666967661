* {
  margin: 0;
  padding: 0;
}
@import "../../../colors/colors.scss";
.frustrated {
  position: relative;
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  .shouting {
    margin-bottom: 100px;
  }
  .frustText {
    position: absolute;
    width: 572px;
    height: 242px;
    background: #e5eeed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 1%;
    .hiringCircle {
      position: absolute;
      left: 10%;
    }
    h6 {
      font-size: 45px;
      line-height: 47px;
      font-weight: 400;
      letter-spacing: -0.03em;
      color: $primary;
      font-family: "Metropolis";
    }
  }
}
