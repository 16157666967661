@import "../../../colors/colors.scss";
.quill {
  margin-top: 20px;
  padding-bottom: 40px;
  .ql-toolbar {
    border-radius: 10px 10px 0px 0px;
    text-align: left;
    padding: 14px !important;
  }
  .ql-container {
    height: 380px;
    border-radius: 0px 0px 10px 10px;
    p {
      font-family: "Metropolis";
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
    }
    li {
      font-family: "Metropolis";
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
    }
    .ql-editor.ql-blank::before {
      font-family: "Metropolis";
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.3);
    }
  }
}
