@import "../../../colors/colors.scss";
.inviteCandidate {
  text-align: center;
  background-color: #f8f8f8;
  padding-bottom: 40px;
  .btnDiv {
    display: none;
  }
  h1 {
    font-family: "Lato";
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #012824;
    padding: 40px 0px;
  }
  p {
    font-family: "Metropolis";
    font-weight: 400;
    font-size: 18px;
    width: 30%;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
    margin: 0px auto;
    padding-bottom: 50px;
  }
  .formDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    h6 {
      font-family: "Lato";
      font-weight: 600;
      font-size: 20px;
      text-align: left;
      color: #012824;
    }
    .inviteForm {
      display: flex;
      width: 100%;
      // min-width: 500px;
      height: 423px;
      background: #ffffff;
      box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
      border-radius: 15px;
      box-sizing: border-box;
      margin: 20px;
      padding: 0px 30px;
      align-items: center;
      position: relative;
      form {
        width: 100%;
      }
      .innerDiv {
        width: 100%;
        h6 {
          margin-bottom: 10px;
        }
        .inputWrapper {
          display: flex;
          column-gap: 15px;
          align-items: center;
          margin-bottom: 20px;
        }
        .inputDiv {
          width: 100%;
          position: relative;
          // margin: 15px auto;
          .forErrors {
            position: absolute;
            color: red;
            font-family: "Lato";
            font-size: 12px;
            font-weight: 600;
            right: 0%;
            bottom: -28%;
          }
        }
        .plusIcon {
          height: 40px;
          cursor: pointer;
          width: 40px;
        }
        .btnInvite {
          width: 30%;
          margin: 30px auto 0px auto;
        }
        .btnCreate {
          margin-top: 30px;
        }
      }
    }
    .bulkInvite {
      display: flex;
      width: 578px;
      min-width: 500px;
      height: 423px;
      background: #ffffff;
      box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
      border-radius: 15px;
      box-sizing: border-box;
      margin: 20px;
      padding: 0px 30px;
      align-items: center;
      position: relative;
      opacity: 0.6;
      cursor: no-drop;
      .innerDiv {
        width: 100%;
        .inputDiv {
          position: relative;
          margin: 15px auto;
        }
        .btnCreate {
          width: 30%;
          margin: 30px auto 0px auto;
        }
      }
      .bulkMail {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 4%;
        right: 32%;
        img {
          margin-right: 15px;
        }
        h5 {
          font-family: "Metropolis";
          font-weight: 600;
          font-size: 18px;
          text-decoration-line: underline;
          color: $primary;
        }
      }
      .premium {
        position: absolute;
        width: 44px;
        height: 44px;
        top: -5%;
        right: -3%;
      }
    }
  }
  .btnDashboard {
    width: 30%;
    margin: 80px auto;
  }
}

@media only screen and (max-width: 1200px) {
  .inviteCandidate {
    .btnDiv {
      display: block;
      margin: 60px 0px 0px 0px;
      display: flex;
      justify-content: center;
    }
  }
}
