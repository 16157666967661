@import "../../../colors/colors.scss";
.noAssessment {
  background-color: #f8f8f8;
  padding: 20px 0px 350px 0px;
  .innerDiv {
    padding: 0px 60px;
    .asssessment {
      padding: 40px 40px;
      p {
        font-family: "Metropolis";
        font-weight: 400;
        font-size: 15px;
        line-height: 14px;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 10px;
      }
      h5 {
        font-family: "Metropolis";
        font-weight: 700;
        font-size: 26px;
        line-height: 25px;
        color: #012824;
      }
    }
    .noAssessment {
      background-color: white;
      border-radius: 10px;
      padding: 0;
      box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
      .columnDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px 60px;
        .tests {
          margin-right: 30rem;
        }
        h5 {
          font-family: "Metropolis";
          font-weight: 600;
          font-size: 16px;
          line-height: 15px;
          color: rgba(0, 0, 0, 0.75);
        }
      }
      .message {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 0px;
        border-top: 1px solid rgba(1, 40, 36, 0.15);
        p {
          font-family: "Metropolis";
          font-weight: 400;
          font-size: 16px;
          line-height: 15px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
