@import "../../../colors/colors.scss";
.welcome {
  margin-right: 12%;
  .headingDiv {
    margin: 0px 40px;
    h1 {
      color: white;
      font-weight: 400;
      font-size: 65px;
      font-family: "Lato";
      line-height: 63px;
      letter-spacing: 0.03em;
      margin-bottom: 30px;
    }
    p {
      color: rgba(255, 255, 255, 0.75);
      font-weight: 400;
      font-size: 22px;
      font-family: "Metropolis";
      line-height: 22px;
      margin-bottom: 50px;
    }
  }
  .profiles {
    display: flex;
    align-items: center;
    margin: 0px 40px;

    p {
      color: rgba(255, 255, 255, 0.65);
      font-size: 18px;
      font-family: "Metropolis";
      line-height: 21px;
      letter-spacing: 0.02em;
      margin: 0px 0px 0px 10px;
    }
  }
}
