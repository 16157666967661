* {
  margin: 0;
  padding: 0;
}
@import "../../../colors/colors.scss";
.uploadimage {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: rgba(229, 238, 237, 1);
  padding: 84px 0px;
  .secondInnerDiv {
    display: flex;
    justify-content: center;
    width: 1300px;
    height: 800px;
    background-color: #c5d7d7;
    border-radius: 15px;
  }
  .mainDiv {
    display: flex;
    justify-content: center;
  }
  .whiteDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 35px;
    padding: 20px;
    width: 620px;
    height: 400px;
    .dotted {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 610px;
      height: 389px;
      border: 2px dashed rgba(4, 40, 38, 0.5);
      border-radius: 25px;
      img {
        cursor: pointer;
      }
      h6 {
        font-family: inherit;
        font-size: 20px;
        font-weight: 500;
        margin-top: 20px;
        color: #012824;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        margin-top: 5px;
        color: rgba(1, 40, 36, 0.75);
      }
      .OR {
        display: flex;
        align-items: center;
        margin: 10px 0px 25px 0px;
        .H-line {
          width: 115px;
          height: 0px;
          border: 1px solid rgba(4, 40, 38, 0.1);
        }
        p {
          font-size: 20px;
          line-height: 23px;
          color: rgba(0, 0, 0, 0.35);
          margin: 0px 10px;
        }
      }
    }
  }
  .ring {
    box-sizing: border-box;
    position: absolute;
    width: 598px;
    height: 598px;
    border-radius: 50%;
    border: 150px solid rgba(4, 40, 38, 0.025);
    right: -5%;
    top: -21%;
  }
  .ringTwo {
    box-sizing: border-box;
    position: absolute;
    width: 726px;
    height: 726px;
    border: 150px solid rgba(4, 40, 38, 0.025);
    border-radius: 50%;
    bottom: -38%;
    left: -9%;
  }
}

@media only screen and (max-width: 1200px) {
  .uploadimage {
    padding: 153px 0px;
    .secondInnerDiv {
      width: 1000px;
      height: 600px;
    }
    .whiteDiv {
      padding: 5px;
      width: 270px;
      height: 350px;
      .dotted {
        width: 260px;
        height: 340px;
        img {
          width: 44px;
          height: 44px;
        }
        h6 {
          font-size: 16px;
          line-height: 30px;
        }
        p {
          font-size: 14px;
          line-height: 10px;
        }
        .OR {
          margin: 20px 0px;
          .H-line {
            width: 64px;
          }
          p {
            font-size: 15px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
