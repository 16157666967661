@import "../../../colors/colors.scss";
.assessmentNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #012824;
  width: 100%;
  height: 75px;
  h1 {
    font-family: "Metropolis";
    font-weight: 500;
    font-size: 20px;
    line-height: 19px;
    color: #ffffff;
    span {
      font-size: 19px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
