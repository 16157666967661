@import "../../../colors/colors.scss";
.assessmentName {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 20px 20px 20px 50px;
  border-radius: 10px;
  margin-bottom: 50px;
  box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
  p {
    font-family: "Metropolis";
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 5px;
  }
  h3 {
    font-family: "Metropolis";
    font-weight: 700;
    font-size: 22px;
    line-height: 21px;
    color: #012824;
  }
  .nameDiv {
    display: flex;
    .testAndTime {
      display: flex;
      align-items: flex-end;
      margin-left: 40px;
      .testsDiv {
        display: flex;
        align-items: flex-end;
        p {
          font-weight: 500;
          font-size: 15px;
          color: rgba(1, 40, 36, 0.7);
          margin: 15px 20px 0px 10px;
        }
      }
      .timerDiv {
        display: flex;
        align-items: flex-end;
        p {
          font-weight: 500;
          font-size: 15px;
          color: rgba(1, 40, 36, 0.7);
          margin: 0px 15px 0px 5px;
        }
      }
    }
  }
  .iconsDiv {
    display: flex;
    align-items: center;
    img {
      margin: 0px 15px;
      cursor: pointer;
    }
  }
}
