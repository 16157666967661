* {
  margin: 0;
  padding: 0;
}
@import "../../../../colors/colors.scss";
.signup {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  .homeIcon {
    z-index: 40;
    margin-top: 25px;
    cursor: pointer;
  }
  h1 {
    margin: 30px 0px 25px 0px;
    font-family: "Lato";
    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
  }
  .or {
    display: flex;
    align-items: center;
    margin: 25px 0px 20px 0px;
    .line {
      width: 170px;
      height: 0px;
      border: 1px solid rgba(4, 40, 38, 0.1);
    }
    p {
      color: rgba(0, 0, 0, 0.35);
      font-family: "Metropolis";
      margin-bottom: 3px;
    }
  }
  h6 {
    font-family: "Metropolis";
    font-size: 16px;
    line-height: 21px;
    color: $primary;
    margin-bottom: 20px;
  }
  form {
    width: 100%;
    .inputDiv {
      margin-bottom: 30px;
      width: 100%;
      position: relative;
      .forErrors {
        color: red;
        font-size: 14px;
        font-family: "Metropolis";
        font-weight: 600;
        position: absolute;
        bottom: -32%;
        user-select: none;
      }
    }
  }
  .google {
    width: 100%;
    img {
      margin-right: 10px;
    }
    p {
      font-size: 20px;
      line-height: 23px;
      color: #1976d2;
      font-family: "Metropolis";
    }
  }
  p {
    color: rgba(4, 40, 38, 1);
    margin: 0px 5px;
  }
  .createAccount {
    display: flex;
    text-align: center;
    flex-direction: column;
    margin-bottom: 30px;
    .btnCreate {
      margin-bottom: 10px;
    }
    .agree {
      font-family: "Metropolis";
      font-size: 16px;
      letter-spacing: -0.02em;
      color: $primary;
      margin: 0px 0px 20px 0px;
      font-weight: 600;
    }
    .navigateToLogin {
      p {
        font-family: "Metropolis";
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.02em;
        color: $primary;
        text-decoration: none;
        font-weight: 400;
        span {
          text-decoration: underline;
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .signup {
    border-radius: 15px;
    width: 400px;
    .or {
      padding: 8px 0px;
      margin: 10px 0px 0px 0px;
      .line {
        width: 183px;
      }
    }
    .homeIcon {
      width: 36px;
      height: 36px;
      margin-bottom: 55px;
    }
    h1 {
      margin: 10px 0px 20px 0px;
      font-size: 26px;
      line-height: 30px;
    }
    form {
      .inputDiv {
        margin-bottom: 0px;
        .forErrors {
          bottom: -22%;
          font-size: 14px;
        }
      }
    }
    .google {
      width: 100%;
      img {
        width: 21px;
        height: 21px;
        margin-right: 5px;
      }
      p {
        font-size: 16px;
        line-height: 18px;
      }
    }
    h6 {
      line-height: 18px;
      margin: 5px 0px 0px 0px;
    }
    .createAccount {
      .btnCreate {
        margin-top: 20px;
      }
      .agree {
        display: none;
      }
      .navigateToLogin {
        p {
          font-size: 14px;
          line-height: 15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 552px) {
  .signup {
    width: 330px;
    .homeIcon {
      margin-bottom: 0px;
    }
    .or {
      .line {
        width: 140px;
      }
    }
  }
}
