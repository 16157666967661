.NoCandidateTable {
  font-family: "Metropolis";
  background-color: white;
  border-radius: 5px 5px 0px 0px;
  padding: 0px 30px;
  box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
  table {
    width: 100%;
  }
  th {
    text-align: left;
    padding: 20px 0px;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.75);
  }
}
