@import "../../../colors/colors.scss";
.dropdown {
  position: relative;
  h5 {
    position: absolute;
    color: red;
    font-size: 14px;
    font-weight: 600;
    font-family: "Lato";
    right: 1%;
    top: -57%;
    margin: 0;
  }
}
.selectDropdown {
  font-family: "Lato";
  position: relative;
  .selectInnerElement__control {
    height: 43px;
    .selectInnerElement:hover {
      background-color: #f8f8f8;
    }
  }
}
