@import "../../../colors/colors.scss";
.LoginScreen {
  position: relative;
  background-color: #c5d7d7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0px;
  overflow-x: hidden;
  .container {
    display: flex;
    justify-content: center;
    .loginLeftDiv {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $primary;
      border-radius: 15px 0px 0px 15px;
      width: 800px;
      min-width: 700px;
    }
    .loginRightDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 810px;
      width: 500px;
      background: #ffffff;
      border-radius: 0px 15px 15px 0px;
      box-sizing: border-box;
      padding: 20px 60px;
      position: relative;
      .homeIcon {
        position: absolute;
        top: 10%;
        z-index: 100;
        cursor: pointer;
      }
      .backWard {
        display: none;
      }
    }
  }
  .circleOne {
    box-sizing: border-box;
    position: absolute;
    border-radius: 50%;
    width: 691px;
    height: 691px;
    left: -1%;
    top: -42%;
    border: 100px solid rgba(229, 238, 237, 0.03);
  }
  .circleTwo {
    box-sizing: border-box;
    position: absolute;
    width: 598px;
    height: 598px;
    border-radius: 50%;
    right: -18%;
    top: -30%;
    border: 150px solid rgba(4, 40, 38, 0.025);
  }
}

@media only screen and (max-width: 1200px) {
  .LoginScreen {
    padding: 0px;
    height: 100vh;
    .loginLeftDiv {
      display: none !important;
    }
    .loginRightDiv {
      height: 720px !important;
      border-radius: 10px !important;
      .backWard {
        display: block !important;
        position: absolute;
        cursor: pointer;
        width: 23px;
        height: 23px;
        top: 5%;
        left: 9%;
      }
    }
    .circleOne {
      display: none;
    }
    .circleTwo {
      display: none;
    }
  }
}
@media only screen and (max-width: 552px) {
  .LoginScreen {
    .loginRightDiv {
      height: 630px !important;
      width: 400px !important;
    }
  }
}
