@import "../../../colors/colors.scss";
.Footer {
  display: flex;
  background-color: $primary;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  padding: 50px 0px;

  .footer-logo {
    cursor: pointer;
    margin-top: 80px;
  }
  .social-media {
    display: flex;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }
  .links {
    display: flex;
    justify-content: space-between;
    .products {
      margin: 0px 40px;
    }
    .careers {
      margin: 0px 40px;
    }
    .aboutus {
      margin: 0px 40px;
    }
    h6 {
      color: white;
      font-size: 17px;
      font-family: "Metropolis";
      font-weight: 600;
      margin: 0px 0px 25px 0px;
    }
    ul {
      padding: 0;
      margin-bottom: 0;
      gap: 0px;
      padding-top: 12px;
      li {
        list-style: none;
        margin: 10px 0px;
        margin-bottom: 0;
        a {
          text-decoration: none;
          color: rgba(255, 255, 255, 0.8);
          font-size: 16px;
          font-weight: 500;
          font-family: "Metropolis";
        }
      }
    }
  }

  .buttonDiv {
    display: flex;
    flex-direction: column;
    h6 {
      color: white;
      font-size: 17px;
      font-family: "Metropolis";
      font-weight: 600;
      margin: 0px 0px 24px 0px;
    }
    .text {
      margin: 13px 0px;
      p {
        color: rgba(255, 255, 255, 0.8);
        font-family: "Metropolis";
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
  .MobMedia {
    display: none;
  }
}
.copyrights {
  background-color: $primary;
  padding: 25px 0px;
  text-align: center;
  h5 {
    margin: 0;
    color: rgba(255, 255, 255, 0.85);
    font-family: "Metropolis";
    font-size: 14px;
    font-weight: 500;
    span {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.footerItems {
  display: block;
}

@media only screen and (max-width: 1200px) {
  .Footer {
    flex-direction: column;
    text-align: center;
    padding: 50px 0px 20px 0px;
    .footer-logo {
      margin-top: 20px;
      img {
        width: 120px;
        height: 40px;
      }
    }
    .social-media {
      display: none;
    }
    .links {
      text-align: left;
      margin: 50px 0px;
      .products {
        margin: 0px 70px;
      }
      .careers {
        margin: 0px 70px;
      }
      .aboutus {
        margin: 0px 70px;
      }
      h6 {
        margin: 0;
        font-size: 15px;
        line-height: 20px;
      }
      ul {
        li {
          a {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
    .buttonDiv {
      h6 {
        font-size: 15px;
        line-height: 20px;
        margin: 0;
      }
      .text {
        margin-bottom: 20px;
        p {
          font-size: 14px;
          line-height: 16px;
        }
      }
      .Email {
        width: 100%;
      }
    }
    .MobMedia {
      display: block;
      img {
        margin: 20px 20px 0px 20px;
      }
    }
  }
  .copyrights {
    h5 {
      font-size: 13px;
      line-height: 15px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .Footer {
    text-align: center;
    .footer-logo {
      margin-top: 10px;
      img {
        width: 135px;
        height: 55px;
      }
    }
    .links {
      .products {
        margin: 0px 25px;
      }
      .careers {
        margin: 0px 25px;
      }
      .aboutus {
        margin: 0px 25px;
      }
      h6 {
        font-weight: 500;
      }
      ul {
        li {
          a {
            font-size: 13px;
          }
        }
      }
    }
    .buttonDiv {
      h6 {
        font-size: 20px;
        line-height: 22px;
        font-weight: 500;
      }
      .text {
        display: flex;
        justify-content: center;
        p {
          font-size: 12px;
          line-height: 13px;
          margin: 15px 3px 0px 0px;
        }
      }
      .Email {
        margin: 0px auto;
      }
    }
    .MobMedia {
      display: block;
      img {
        margin: 20px 20px 0px 20px;
      }
    }
  }
  .copyrights {
    h5 {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
