.section {
    width: 100%;
    height: auto;
    display: flex;
    background: #e5eeed;
    padding: 30px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.bottom {
    display: flex;
    width: 100%;
}

.heading {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 105%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #042826;
    width: 100%;
    height: 170px;
    text-align: center;
}

.left {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.left-text {
    width: 577px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 140%;
    color: #042826;
}

.btn-div1 {
    width: 577px;
}

.right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.group-img {
    width: 100%;
}

@media (max-width: 960px) {
    .bottom {
        flex-direction: column;
    }

    .left {
        width: 100%;
        padding: 30px 10px;
        box-sizing: border-box;
    }

    .left-text {
        width: 100%;
    }

    .btn-div1 {
        width: 100%;
    }

    .right {
        width: 100%;
        padding: 30px 10px;
        box-sizing: border-box;
    }

    .group-img {
        width: 100%;
    }
}