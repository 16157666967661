@import "../../../colors/colors.scss";
.premium {
  width: 59%;
  margin: 0px auto;
  position: relative;
  h1 {
    text-align: center;
    font-family: "Lato";
    font-weight: 600;
    font-size: 22px;
    color: #093431;
    margin: 80px 0px 30px 0px;
  }
  p {
    font-family: "Metropolis";
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
    margin-bottom: 15px;
    span {
      color: rgba(83, 140, 136, 1);
    }
  }
  .btnGoTo {
    width: 50%;
    margin: 25px auto 40px auto;
  }
  .exit {
    position: absolute;
    top: -25%;
    right: -26%;
    cursor: pointer;
  }
}
