@import "../../../colors/colors.scss";
.preview {
  text-align: center;
  position: relative;
  padding-bottom: 50px;
  h1 {
    font-family: "Lato";
    font-size: 26px;
    color: #012824;
    margin: 50px 0px 25px 0px;
  }
  p {
    font-family: "Metropolis";
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.75);
    margin-bottom: 30px;
  }
  .testInfo {
    display: flex;
    width: 760px;
    height: 94px;
    background: rgba(229, 238, 237, 0.75);
    border-radius: 15px;
    margin: 0px auto;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0px 30px;
    align-items: center;
    .information {
      display: flex;
      h6 {
        font-family: "Metropolis";
        font-weight: 400;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5);
        text-align: left;
        margin-bottom: 5px;
      }
      h5 {
        font-family: "Metropolis";
        font-weight: 500;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.85);
      }
      img {
        margin-left: 20px;
        align-self: center;
      }
    }
  }
  h2 {
    font-family: "Lato";
    font-weight: 700;
    font-size: 19px;
    color: #012824;
    margin-bottom: 30px;
  }
  .moreInfo {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    padding: 0px 350px 0px 100px;
    .description {
      text-align: left;
      width: 50%;
    }
    .skillsCoverd {
      text-align: left;
      .skills {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        img {
          margin-right: 10px;
        }
        p {
          font-family: "Metropolis";
          font-weight: 400;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.6);
          margin: 0;
        }
      }
    }
  }
  .totalQuestionsWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    .previewText {
      font-size: 22px;
      font-weight: bold;
    }
    .allQuestions {
      margin: auto;
    }
    .topicText {
      font-weight: bolder;
      .topicName {
        font-weight: normal;
      }
    }
    .questionList {
      color: black;
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;
      gap: 15px;
      .questions {
        font-size: 20px;
        color: black;
      }
      .questionOptions {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-left: 20px;
        .answers {
          color: #02cc02;
          font-weight: 500;
        }
        .wrongAnswer {
          color: #ee4b2b;
          font-weight: 500;
        }
      }
    }
  }
  .aboutReleventSkills {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    padding: 0px 300px 0px 100px;
    .authorExpert {
      text-align: left;
      width: 50%;
      .imgDiv {
        display: flex;
        align-items: center;
        img {
          margin-right: 30px;
          width: 94px;
          height: 94px;
        }
        p {
          margin: 0;
        }
      }
    }
    .relevant {
      text-align: left;
      p {
        margin: 0;
      }
    }
  }
  .exit {
    position: absolute;
    top: 0%;
    right: 2%;
    cursor: pointer;
  }
}
