@import "../../../colors/colors.scss";
.dark {
  background-color: $primary;
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 0px;
    height: 82px;
    box-sizing: border-box;
    .logoDiv {
      padding-top: 5px;
      cursor: pointer;
      img {
        width: 95px;
        height: 35px;
      }
    }
    ul {
      display: flex;
      list-style: none;
      padding-left: 0;
      li {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.02em;
        margin: 0px 34px;
        font-family: "Lato";
        color: white;
        cursor: pointer;
        &.active {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
  .btnDiv {
    .btnDark {
      display: flex;
      gap: 3px;
      .signUpBtnText {
        color: white;
        text-align: center;
        font-family: "Lato";
        font-size: 16px;
        font-weight: 700;
        line-height: 115%;
        letter-spacing: 0.48px;
      }
      .loginBtnText {
        color: #012824;
        text-align: center;
        font-family: "Lato";
        font-size: 16px;
        font-weight: 700;
        line-height: 115%;
        letter-spacing: 0.48px;
      }
    }
    .moreMenu {
      display: none;
    }
    .DarkMenu {
      display: none;
    }
  }

  .darkLogo {
    display: none;
  }
  .btnLight {
    display: none;
  }
}
// ---------------------------------------------------------
.light {
  background-color: #f8f8f8;
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0px;

    ul {
      display: flex;
      list-style: none;
      padding-left: 0;
      li {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.02em;
        margin: 0px 34px;
        font-family: "Lato";
        color: $primary;
        &.active {
          color: #57878598;
        }
      }
    }
    .btnDiv {
      display: flex;
      .btnDark {
        display: none;
      }
    }
    .moreMenu {
      display: none;
    }
    .DarkMenu {
      display: none;
    }
    .lightLogo {
      display: none;
    }
    .btnLight {
      display: block;
      display: flex;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .mobNav {
    position: relative;
    z-index: 100000;
    padding: 50px 70px 50px 50px;
    ul {
      list-style: none;
      text-decoration: none;

      li {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 15px;
        cursor: pointer;
        color: #042826;
      }
    }
    .btnModal {
      margin-top: 40px;
      .mobLogin {
        text-align: center;
        margin: 10px 0px 20px 0px;
      }
    }
  }

  .dark {
    nav {
      padding: 14px 0px;
      .logo-div {
        img {
          width: 80px;
          height: 30px;
        }
      }
      ul {
        display: none;
      }
    }
    .btnDiv {
      .btnDark {
        display: none;
      }
      .moreMenu {
        display: block;
        cursor: pointer;
      }
    }
  }
  .light {
    nav {
      padding: 14px 0px;
      .logo-div {
        img {
          width: 80px;
          height: 30px;
        }
      }
      ul {
        display: none;
      }
    }
    .btnDiv {
      .btnLight {
        display: none;
      }
      .moreMenu {
        display: none;
      }
      .DarkMenu {
        display: block;
        cursor: pointer;
      }
    }
  }
}
