@import "../../../colors/colors.scss";
.ThankyouDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c5d7d7;
  padding: 80px 0px;
  margin: auto;
  // height: 80vh;
  font-family: "Metropolis";
  .ThankyouNote {
    background-color: white;
    padding: 60px 80px;
    text-align: center;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    h1 {
      font-weight: 600;
      font-size: 32px;
      line-height: 65px;
      letter-spacing: -0.01em;
      color: #000000;
      margin-bottom: 10px;
    }
    p {
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: rgba(0, 0, 0, 0.75);
    }
    .note {
      padding: 20px 10px;
      background: #e5eeed;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: rgba(0, 0, 0, 0.7);
        span {
          color: #000000;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }

    textarea {
      width: 370px !important;
      height: 176px !important;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      outline: none;
      font-size: 17px;
      font-family: "Metropois";
      padding: 10px 20px 20px 20px;
      margin-top: 30px;
    }
    textarea::placeholder {
      font-family: "Metropolis";
      font-weight: 400;
      font-size: 17px;
      line-height: 25px;
      letter-spacing: -0.01em;
      color: rgba(0, 0, 0, 0.3);
    }
    .btnSubmit {
      align-self: center;
      width: 35%;
      margin: 40px 0;
    }
  }
}

@media screen and (max-width: 800px) {
  .ThankyouDiv {
    height: 100%;
    // padding: 0px;
    .ThankyouNote {
      .closeWindow {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .thankDesc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;
      }
      .note {
        .thoughts {
          display: flex;
          padding: 0px 15px;
        }
        .textArea {
          padding: 0 15px;
        }
      }
      padding: 0px;
      textarea {
        width: auto !important;
        height: 150px !important;
      }
    }
  }
}
