@import "../../../colors/colors.scss";
.review {
  background-color: #f8f8f8;
  .innerDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0px 50px 50px 50px;
    .btnDiv {
      display: none;
    }
    .remainingProgressDiv {
      align-self: center;
      width: 100%;
    }
    h2 {
      font-family: "Metropolis";
      font-weight: 500;
      font-size: 18px;
      color: #000000;
      text-align: left;
      margin: 60px 0px 30px 0px;
    }
    .selectedAssessment {
      margin-top: 30px;

      display: flex;
      justify-content: space-between;
      background-color: white;
      padding: 20px 20px 20px 50px;
      border-radius: 10px;
      margin-bottom: 50px;
      box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
      p {
        font-family: "Metropolis";
        font-weight: 400;
        font-size: 14px;
        line-height: 13px;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 5px;
      }
      h3 {
        font-family: "Metropolis";
        font-weight: 700;
        font-size: 22px;
        line-height: 21px;
        color: #012824;
      }
      .nameDiv {
        display: flex;
        align-items: center;

        .testAndTime {
          display: flex;
          align-items: flex-end;
          margin-left: 40px;
          img {
            margin-right: 5px;
          }
          .testsDiv {
            display: flex;
            align-items: baseline;
            margin-right: 30px;
            p {
              font-weight: 500;
              font-size: 15px;
              color: rgba(1, 40, 36, 0.7);
              margin: 20px 0px 0px 0px;
            }
          }
          .timerDiv {
            display: flex;
            align-items: baseline;
            p {
              font-weight: 500;
              font-size: 15px;
              color: rgba(1, 40, 36, 0.7);
              margin: 20px 0px 0px 0px;
            }
          }
        }
      }
    }
    .testTable {
      margin-top: 30px;
    }
    .customQuestion {
      margin-top: 30px;
    }
    .btnPublish {
      width: 425px;
      margin: 40px auto;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .review {
    .innerDiv {
      .btnDiv {
        display: block;
        margin: 60px 0px 0px 0px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .review {
    .innerDiv {
      padding: 0px 20px 20px 20px;
      .selectedAssessment {
        .nameDiv {
          .testAndTime {
            display: none;
          }
        }
      }
      .btnPublish {
        width: 100%;
      }
    }
  }
}
