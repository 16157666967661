@import "../../../colors/colors.scss";
.Settings {
  background-color: #f8f8f8;
  padding: 80px 0px 50px 0px;
  box-sizing: border-box;
  .dashBtn {
    display: none;
  }
  .innerDiv {
    display: flex;
    margin: auto;
    .contentDiv {
      width: 100%;
      background: rgba(255, 255, 255, 0.75);
      box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
      border-radius: 20px;
      padding: 50px;
      box-sizing: border-box;
      min-height: 600px;
      &.management {
        background-color: transparent;
      }
      &.billingplan {
        background-color: transparent;
      }
    }
    .sideBar {
      width: 240px;
      height: 370px;
      background: rgba(255, 255, 255, 0.75);
      box-shadow: 0px 12px 24px rgb(134 134 134 / 8%);
      border-radius: 20px;
      margin-right: 40px;
      padding: 40px 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-shrink: 0;
      box-sizing: border-box;
      p {
        font-family: "Metropolis";
        font-weight: 500;
        font-size: 15px;
        line-height: 40px;
        color: rgba(0, 0, 0, 0.7);
        cursor: pointer;
        padding-left: 40px;
      }
      p:hover {
        background-color: rgba(212, 225, 225, 1);
      }
      .active {
        background-color: rgba(212, 225, 225, 1);
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .Settings {
    padding: 0px 0px 50px 0px;
    .dashBtn {
      display: flex;
      justify-content: center;
      margin: 60px 0px 40px 0px;
    }
    .innerDiv {
      .sideBar {
        display: none;
      }
      .contentDiv {
        display: flex;
        justify-content: center;
        padding: 30px;
        text-align: left;
      }
    }
  }
}
