@import "../../colors/colors.scss";
.waitList {
  display: flex;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #c5d7d7;
  justify-content: center;
  z-index: 50;
  height: 100vh;

  .mainDiv {
    display: flex;
    justify-content: center;
  }
  .wait {
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 80px;
    border-radius: 15px 0px 0px 15px;
  }
  .form {
    background-color: white;
    border-radius: 0px 15px 15px 0px;
    padding: 120px 60px 160px 60px;
    .mainDiv {
      text-align: center;
      display: flex;
      flex-direction: column;
      width: 330px;
      .darkLogo {
        padding: 0px 0px 40px 0px;
        z-index: 40;
        img {
          cursor: pointer;
        }
      }

      h6 {
        font-family: "Lato";
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: $primary;
        margin-bottom: 40px;
      }
      .textfield {
        position: relative;
        text-align: left;
        margin-bottom: 20px;
        width: 100%;
        .forErrors {
          color: red;
          font-size: 16px;
          position: absolute;
          font-weight: 600;
          top: 2%;
          right: 0%;
          font-family: "Lato";
        }
        h4 {
          font-size: 15px;
          color: rgba(0, 0, 0, 0.75);
          font-weight: 400;
          margin-bottom: 4px;
          font-family: "Lato";
        }
      }
      .btnNext {
        margin: 40px 0px 0px 0px;
      }
      .backWord {
        display: none;
      }
    }
  }
  .Circle {
    box-sizing: border-box;
    position: absolute;
    width: 726px;
    height: 726px;
    border: 150px solid rgba(4, 40, 38, 0.025);
    border-radius: 50%;
    bottom: -42%;
    left: -4%;
  }
  .CircleTwo {
    box-sizing: border-box;
    position: absolute;
    width: 598px;
    height: 598px;
    border: 150px solid rgba(4, 40, 38, 0.025);
    border-radius: 50%;
    top: -27%;
    right: -4%;
  }
}

@media only screen and (max-width: 1400px) {
  .waitList {
    .wait {
      img {
        width: 420px;
        height: 330px;
      }
    }
  }
  .form {
    padding: 120px 40px 220px 40px;
  }
}

@media only screen and (max-width: 1200px) {
  .waitList {
    padding: 30px 0px;
    .wait {
      display: none;
    }
    .form {
      border-radius: 15px;
      padding: 60px 30px;
      .darkLogo {
        padding: 50px 0px 20px 0px;
        img {
          width: 85px;
          height: 35px;
        }
      }
      p {
        font-size: 16px;
        line-height: 18px;
      }
      .backWord {
        position: absolute;
        display: block;
        width: 23px;
        height: 23px;
        top: 5%;
        left: 7%;
      }
    }
    .Circle {
      display: none;
    }
    .CircleTwo {
      display: none;
    }
  }
}
