@import "../../../colors/colors.scss";
.quality {
  display: flex;
  flex-direction: column;
  background-color: $primary;
  width: 100%;
  align-items: center;
  padding-top: 40px;
  text-align: center;
  .quality-heading {
    text-align: center;
    .mobText {
      display: none;
    }
    h2 {
      color: white;
      margin: 40px 0px 60px 0px;
      font-family: "Lato";
      font-size: 36px;
      font-weight: 700;
    }
    h5 {
      color: rgba(255, 255, 255, 0.8);
      margin: 0;
      font-family: "Metropolis";
      font-size: 19px;
      font-weight: 400;
    }
  }

  img {
    margin-top: 70px;
    align-items: baseline;
  }
}

// ------------------------------- Tablet Responsive ---------------------------

@media only screen and (max-width: 1200px) {
  .quality {
    .quality-heading {
      h2 {
        font-size: 30px;
        line-height: 31px;
      }
      h5 {
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}

// ------------------------------- Mobile Responsive ---------------------------

@media only screen and (max-width: 992px) {
  .quality {
    .quality-heading {
      .mainText {
        display: none;
      }
      .mobText {
        display: block;
        text-align: center;
        .btnStarted {
          margin: 40px 0px 0px 0px;
        }
      }
      h2 {
        font-size: 22px;
        line-height: 24px;
        margin: 30px 0px;
      }
      h5 {
        font-size: 16px;
        line-height: 18px;
      }
    }
    img {
      margin-top: 30px;
    }
  }
}
