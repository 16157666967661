@import "../../../colors/colors.scss";
.ForgetPassword {
  position: relative;
  background-color: #c5d7d7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-x: hidden;
  padding: 40px 0px;
  box-sizing: border-box;
  .innerDiv {
    width: 100%;
    height: 85vh;
    min-height: 600px;
    background-color: white;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      font-family: "Metropolis";
      font-weight: 700;
      font-size: 32px;
      color: $primary;
      margin-bottom: 40px;
    }
    p {
      width: 40%;
      font-family: "Lato";
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      color: rgba(0, 0, 0, 0.75);
    }
    Form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .forgetEmail {
      width: 30%;
      margin: 35px 0px;
      position: relative;
      .forErrors {
        position: absolute;
        font-family: "Lato";
        color: red;
      }
    }
    .btnSend {
      width: 15%;
    }
  }
  .gradient {
    box-sizing: border-box;
    position: absolute;
    width: 598px;
    height: 598px;
    border: 150px solid rgba(4, 40, 38, 0.025);
    border-radius: 50%;
    top: -25%;
    right: -8%;
  }
  .secondGradient {
    box-sizing: border-box;
    position: absolute;
    width: 726px;
    height: 726px;
    border-radius: 50%;
    border: 150px solid rgba(4, 40, 38, 0.025);
    bottom: -30%;
    left: -10%;
  }
}

@media only screen and (max-width: 1400px) {
  .ForgetPassword {
    .innerDiv {
      p {
        width: 70%;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .ForgetPassword {
    .innerDiv {
      .forgetEmail {
        min-width: 300px;
        margin: 20px 0px 30px 0px;
      }
      .btnSend {
        min-width: 200px;
      }
    }
    .gradient {
      display: none;
    }
    .secondGradient {
      display: none;
    }
  }
}
@media only screen and (max-width: 767px) {
  .ForgetPassword {
    .innerDiv {
      h1 {
        font-size: 28px;
        margin-bottom: 15px;
      }
      p {
        width: 80%;
        font-size: 16px;
      }
      .btnSend {
        min-width: 300px;
      }
    }
  }
}
