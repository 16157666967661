@import "../../../colors/colors.scss";
.pages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .itemsPage {
    display: flex;
    align-items: center;
    h6 {
      margin-right: 15px;
      font-family: "Metropolis";
      color: rgba(1, 40, 36, 0.65);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 23px;
    }
    .totalPages {
      display: flex;
      align-items: center;
      column-gap: 10px;
      .defaultItemsPerPage {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        cursor: pointer;
        p {
          font-family: "Metropolis";
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
          margin: 0px;
          font-weight: 500;
          line-height: 23px;
        }
      }
      .selectedItemsPerPage {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ecf2f2;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        cursor: pointer;
        p {
          font-family: "Metropolis";
          font-size: 14px;
          color: #042826;
          margin: 0px;
          font-weight: 500;
          line-height: 23px;
        }
      }
    }
  }
  ul {
    display: flex;
    justify-content: space-between;
    width: 110px;
    li {
      text-decoration: none;
      list-style: none;
      cursor: pointer;
      font-family: "Metropolis";
      font-weight: 500;
      font-size: 14px;
      color: rgba(1, 40, 36, 0.65);
    }
  }
}



.active-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ecf2f2;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        a{
          color: #042826;
        }

}
.pagination{
  align-items: center;
}
.disabled{
  opacity: 0.5;
}



