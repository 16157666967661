@import "../../../colors/colors.scss";
.Header {
  background-color: $primary;
  position: relative;
  overflow: hidden;
  padding-bottom: 70px;
  .headerDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headerLeft {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .headerLeft-container {
        width: 100%;
      }
      .description {
        color: rgba(255, 255, 255, 0.8);

        font-family: Lato;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
      .getStartButton {
        display: flex;
        justify-content: start;
      }
      h1 {
        position: relative;
        color: white;
        font-size: 56px;
        font-weight: 700;
        line-height: 64px;
        font-family: "Lato";
        width: fit-content;
        .verticalLines {
          position: absolute;
          right: -6%;
          top: -26px;
          img {
            width: 24px;
            height: 31px;
          }
        }
        .curveLine {
          position: absolute;
          bottom: -25px;
          left: 0;
          img {
            width: 150px;
            height: 13px;
          }
        }
        .curve {
          display: none;
        }
      }
      p {
        color: white;
        font-size: 17px;
        line-height: 27px;
        font-weight: 400;
        padding: 30px 0px;
        font-family: "Metropolis";
        width: 65%;
      }
    }
    .headerRight {
      margin-top: 100px;
      z-index: 10;
      .cardWrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        row-gap: 23px;
        column-gap: 37px;
        justify-content: flex-end;
      }
      .upperProfile {
        display: flex;
        justify-content: flex-end;
        gap: 23px;
        margin-bottom: 37px;
      }
      .lowerProfile {
        display: flex;
        gap: 23px;
      }
      .forTablet {
        display: none;
      }
      .forMob {
        display: none;
      }
    }
    .slider {
      display: none;
    }
  }
  .firstCircle {
    box-sizing: border-box;
    position: absolute;
    border-radius: 50%;
    width: 900px;
    height: 902px;
    left: -19%;
    top: 25%;
    bottom: -210px;
    border: 100px solid rgba(255, 255, 255, 0.02);
    z-index: 1;
  }
  .secondCircle {
    box-sizing: border-box;
    position: absolute;
    border-radius: 50%;
    width: 690px;
    height: 690px;
    right: -13%;
    top: -28%;
    border: 100px solid rgba(255, 255, 255, 0.02);
  }
}

@media only screen and (max-width: 1400px) {
  .Header {
    .headerRight {
      margin-left: 30px;
      .upperProfile {
        .need {
          display: none;
        }
      }
      .lowerProfile {
        .need {
          display: none;
        }
      }
    }
  }
}

// ------------------------ Tablet Responsive -------------------------

@media only screen and (max-width: 1200px) {
  .Header {
    padding: 90px 20px 30px 20px;
    .headerDiv {
      flex-direction: column;
      text-align: center;
      .headerLeft {
        .headerLeft-container {
          .getStartButton {
            justify-content: center;
          }
        }
        h1 {
          font-size: 46px;
          margin: 0px auto;
          .verticalLines {
            top: -19px;
          }
          .curveLine {
            display: none;
          }
          .curve {
            display: block;
            position: absolute;
            left: 20px;
            bottom: -22px;
            img {
              width: 120px;
            }
          }
        }
        p {
          color: rgba(255, 255, 255, 0.8);
          padding: 40px 0px;
          font-size: 18px;
          line-height: 22px;
          margin: 0px auto;
          width: 55%;
        }
      }
      .headerRight {
        margin-top: 50px;
        .upperProfile {
          display: none;
        }
        .lowerProfile {
          display: none;
        }
        .forTablet {
          display: block;
          .carousel-root {
            display: flex;
            justify-content: center;
          }
        }
      }
      .slider {
        display: block;
        margin: 20px 0px 30px 0px;
      }
    }
    .firstCircle {
      display: none;
    }
    .secondCircle {
      display: none;
    }
  }
}

// ------------------------ Mobile Responsive -------------------------

@media only screen and (max-width: 992px) {
  .Header {
    padding: 70px 20px 20px 20px;
    text-align: center;
    .headerDiv {
      .headerLeft {
        .headerLeft-container {
          .getStartButton {
            justify-content: center;
          }
        }
        h1 {
          font-size: 34px;
          line-height: 30px;
          .verticalLines {
            right: -15px;
            top: -18px;
            img {
              width: 16px;
              height: 16px;
            }
          }
          .curveLine {
            display: block;
            left: 5px;
            bottom: -14px;
            img {
              width: 110px;
              height: 8px;
            }
          }
          .curve {
            display: none;
          }
        }
        p {
          font-size: 14px;
          line-height: 17px;
          padding: 25px 0px;
        }
      }
      .headerRight {
        .forTablet {
          display: none;
        }
        .forMob {
          display: block;
          .carousel-root {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
