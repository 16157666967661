@import "../../../colors/colors.scss";
.talent {
  display: flex;
  flex-direction: column;
  background-color: #e7eded;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 80px 0px;

  .talent-section-heading{
    text-align: center;
    font-family: "Lato";
    color: $primary;
    margin: 0;
    font-size: 36px;
    font-weight: 700;

  
  p {
    display: block;
  }
  
  .talentDiv {
    align-items: center;
    padding: 70px 0px 0px 0px;

    .upperDiv {
      display: flex;
      justify-content: space-around;
      background-color: #e7eded;
      padding: 30px 40px 0px 0px;
    }
    .lowerDiv {
      display: flex;
      justify-content: space-around;
      padding: 60px 0px 0px 40px;
    }
  }


}
}
@media only screen and (max-width: 1200px) {
  .talent {
    h2 {
      font-size: 30px;
      line-height: 31px;
    }
    p {
      color: $primary;
      display: block;
      text-align: center;
      font-size: 18px;
      line-height: 22px;
      font-family: "Lato";
      margin: 25px 0px;
    }
    .talentDiv {
      .upperDiv {
        justify-content: center;
      }
      .lowerDiv {
        justify-content: center;
      }
    }
    .rightBlock {
      margin: 70px 0px 0px 60px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .talent {
    padding-top: 50px;
    h2 {
      font-size: 22px;
      line-height: 25px;
    }
    .text {
      display: none;
    }
    .talentDiv {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px 0px 40px 0px;
      .upperDiv {
        flex-direction: column;
        margin: 0;
        padding: 0;
      }
      .lowerDiv {
        flex-direction: column;
        margin: 0;
        padding: 0;
      }
    }
    .rightBlock {
      margin: 0;
    }
  }
}
.top-talent-cards-wrapper{
  display: flex ;
  flex-wrap: wrap;
  gap: 160px;
    justify-content: center;
    margin-top: 60px;
}

.text{
  display: none;
}
