@import "../../../colors/colors.scss";
.recruitment {
  background-color: #e5eeed;
  width: 100%;
  padding-bottom: 116px;
  .mobText {
    display: none;
  }
  h2 {
    text-align: center;
    color: #193b37;
    font-family: "Lato";
    font-size: 36px;
    font-weight: 700;
    padding: 94px 0px 20px 0px;
  }
  .main {
    background-color: transparent;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: none !important;
    .mainContent {
      line-height: 30px;
    }
    .btnStarted {
      margin-top: 40px;
    }
    h5 {
      color: $primary;
      line-height: 22px;
      font-family: "Metropolis";
      font-size: 18px;
      font-weight: 400;
      margin-top: 30px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .recruitment {
    h2 {
      font-size: 30px;
      line-height: 31px;
      padding: 70px 0px 0px 0px;
    }
    .main {
      flex-direction: column;
      text-align: center;
      h5 {
        font-size: 16px;
        line-height: 22px;
      }
      img {
        margin-top: 50px;
      }
    }
  }
  .peopleGroup {
    width: 351px;
    height: 292px;
  }
}

@media only screen and (max-width: 992px) {
  .recruitment {
    .mainText {
      display: none;
    }
    .mobText {
      display: block;
    }
    h2 {
      font-size: 22px;
      line-height: 24px;
      padding-top: 50px;
    }
    .main {
      padding-top: 20px;
      h5 {
        font-size: 16px;
        text-align: left;
        margin-bottom: 20px;
      }
      img {
        width: 350px;
        height: 290px;
        margin-top: 50px;
      }
    }
  }
  .peopleGroup {
    width: 351px;
    height: 292px;
  }
}
