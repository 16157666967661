@import "../../../colors/colors.scss";
.UIdark {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 275px;
  height: 340px;
  background: linear-gradient(32.72deg, #042927 1.49%, #034340 98.75%);
  border-radius: 15px;
  .timeDuration {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;
    p {
      color: white;
      font-size: 16px;
      line-height: 16px;
      font-family: "Metropolis";
      span {
        font-size: 12px;
        line-height: 14px;
      }
    }
    .Add {
      position: relative;
      margin-left: 43px;
      img {
        position: absolute;
        top: -30%;
        right: -19%;
        cursor: pointer;
      }
      .mobbtn {
        display: none;
      }
    }
  }
  .mainText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 0px 30px;

    .darkLine {
      display: none;
    }
    h4 {
      font-size: 24px;
      line-height: 22px;
      color: white;
      font-family: "Lato";
    }
    .testDescription {
      color: #fff;
      font-family: "Lato";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      letter-spacing: 0.14px;
      opacity: 0.6;
    }
  }
  .moreLinks {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    img {
      cursor: pointer;
    }
    .moreIcons {
      margin-right: 30px;
      img {
        margin-right: 10px;
      }
    }
    .darkView {
      display: none;
    }
    .bottomText {
      display: none;
    }
  }
}

.UIlight {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 275px;
  height: 340px;
  border: 2px solid #034340;
  border-radius: 15px;
  background-color: white;

  .timeDuration {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;
    p {
      color: black;
      font-size: 16px;
      line-height: 16px;
      font-family: "Metropolis";
      span {
        color: rgba(1, 40, 36, 1);
        font-size: 12px;
        line-height: 14px;
      }
    }
    .Add {
      position: relative;
      margin-left: 43px;
      img {
        position: absolute;
        top: -30%;
        right: -19%;
      }
      .mobbtn {
        display: none;
      }
    }
  }
  .mainText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 0px 30px;
    .lightLine {
      display: none;
    }
    h4 {
      font-size: 24px;
      line-height: 22px;
      color: #012824;
      font-family: "Lato";
    }
    p {
      font-size: 14px;
      line-height: 16px;
      color: #012824;
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 3.6em;
      word-wrap: break-word;
      font-family: "Metropolis";
      display: inline-block;
      width: 200px;
    }
  }
  .moreLinks {
    display: flex;
    justify-content: space-around;
    width: 100%;
    img {
      cursor: pointer;
    }
    .moreIcons {
      margin-right: 30px;
      img {
        margin-right: 10px;
      }
    }
    .lightView {
      display: none;
    }
    .bottomText {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .UIdark {
    width: 176px;
    height: 220px;
    .timeDuration {
      justify-content: flex-end;
      margin: 0px 35px 0px 0px;
      p {
        display: none;
      }
      .Add {
        img {
          width: 25px;
          height: 25px;
        }
        .btnAdd {
          display: none;
        }
        .mobbtn {
          display: block;
        }
      }
    }
    .mainText {
      h4 {
        font-size: 16px;
        line-height: 15px;
      }
      .lightLine {
        width: 10%;
      }
      p {
        font-size: 10px;
        line-height: 12px;
        width: 130px;
      }
    }

    .moreLinks {
      justify-content: space-evenly;
      .moreIcons {
        margin: 0;
        .figma {
          width: 12px;
          height: 18px;
        }
        .adobe {
          width: 17px;
          height: 17px;
        }
        .diamond {
          width: 17px;
          height: 17px;
        }
      }
      .darkView {
        display: none;
      }
      .lightView {
        display: none;
      }
      .bottomText {
        display: block;
        p {
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
        }
      }
    }
  }

  .UIlight {
    width: 176px;
    height: 220px;
    border: none;
    .timeDuration {
      justify-content: flex-end;
      margin: 0px 35px 0px 0px;
      p {
        display: none;
      }
      .Add {
        img {
          width: 25px;
          height: 25px;
        }
        .btnAdd {
          display: none;
        }
        .mobbtn {
          display: block;
        }
      }
    }
    .mainText {
      h4 {
        font-size: 16px;
        line-height: 15px;
      }
      .lightLine {
        width: 10%;
      }
      p {
        font-size: 10px;
        line-height: 12px;
        width: 130px;
      }
    }

    .moreLinks {
      justify-content: space-evenly;
      .moreIcons {
        margin: 0;
        .figma {
          width: 12px;
          height: 18px;
        }
        .adobe {
          width: 17px;
          height: 17px;
        }
        .diamond {
          width: 17px;
          height: 17px;
        }
      }
      .darkView {
        display: none;
      }
      .lightView {
        display: none;
      }
      .bottomText {
        display: block;
        p {
          font-weight: 400;
          font-size: 12px;
          color: rgba(3, 59, 55, 1);
        }
      }
    }
  }
}
