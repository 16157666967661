@import "../../../colors/colors.scss";
.changeRole {
  width: 40%;
  .inputDiv {
    width: 100%;
    position: relative;
    .forErrors {
      position: absolute;
      color: red;
      font-size: 16px;
      font-weight: 600;
      font-family: "Lato";
      right: 2%;
      top: 3%;
    }
  }
  .jobroleDiv {
    p {
      font-size: 15px;
      color: rgba(0, 0, 0, 0.75);
      font-family: "Lato";
      margin: 10px 0px 5px 0px;
    }
  }
  .btnDiv {
    width: 100%;
    margin-top: 40px;
  }
}
@media only screen and (max-width: 992px) {
  .changeRole {
    width: 100%;
    .btnDiv {
      width: 100%;
    }
  }
}
