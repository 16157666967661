@import "../../../colors/colors.scss";
.billingPlan {
  background-color: #f4f7f7;
  width: 340px;
  height: 520px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
  border-radius: 20px;
  padding: 40px 15px 0px 15px;
  margin: 0px 10px 30px 10px;
  &.highlighted {
    background-color: white;
  }
  h1 {
    font-size: 26px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 30px;
    font-family: "Lato";
  }
  p {
    color: rgba(0, 0, 0, 0.45);
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    margin-right: 10rem;
    font-family: "Metropolis";
  }
  .yougetDiv {
    padding: 20px 0px;
    margin-right: 6rem;
    .youGet {
      align-self: flex-start;
      display: flex;
      align-items: center;
      margin: 10px 0px;
      p {
        font-family: "Metropolis";
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #35353f;
        margin: 0;
        margin-left: 10px;
      }
    }
  }

  h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    margin: 30px 0px;
    font-family: "Metropolis";
    span {
      color: rgba(4, 40, 38, 0.65);
      font-size: 18px;
    }
  }
  .highlighted {
    background-color: white;
  }
}
