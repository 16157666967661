.nav-wrapper {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 40px;
  box-sizing: border-box;
  background: #042826;
}
.nav-btn {
  display: none;
}
.navItems {
  display: flex;
  gap: 30px;
}
.ul-list {
  display: none;
}
.btn-div-mobile {
  display: none;
}

ul li {
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: pointer;
  list-style: none;
}

.btn-div {
  display: flex;
  gap: 10px;
}
@media (max-width: 768px) {
  ul {
    /* display: none; */
  }
  .ul-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .btn-div {
    display: none;
  }
  .btn-div-mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .nav-btn {
    display: block;
    width: 40px;
  }
  .show-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    width: 300px;
    height: 100vh;
    gap: 20px;
    background-color: darkgray;
    position: fixed;
    top: 90px;
    left: 0px;
    transition: 0.5s;
  }
  .hide-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    display: block;
    width: 300px;
    height: 100vh;
    background-color: darkgray;
    position: fixed;
    top: 90px;
    left: -300px;
    transition: 0.5s;
  }
}
