@import "../../../colors/colors.scss";
.CandidateDesktop {
  background-color: #c5d7d7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
  .formDiv {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 604px;
    padding: 90px 60px;
    background-color: white;
    box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
    border-radius: 10px;
    h6 {
      font-family: "Metropolis";
      font-weight: 500;
      font-size: 18px;
      line-height: 65px;
      letter-spacing: -0.01em;
      color: #000000;
      align-self: flex-start;
      margin-top: 20px;
    }
    .inputDiv {
      width: 100%;
      margin: 5px 0px;
      position: relative;
      .forErrors {
        position: absolute;
        color: red;
        font-size: 14px;
        font-weight: 600;
        font-family: "Lato";
        right: 0%;
        top: 3%;
      }
    }
    .checkBoxDiv {
      display: flex;
      align-items: center;
      margin: 30px 0px;
      position: relative;
      .forErrors {
        position: absolute;
        color: red;
        font-size: 14px;
        font-weight: 600;
        font-family: "Lato";
        bottom: -50%;
      }
      .checkBox {
        margin-right: 20px;
        accent-color: $primary;
      }
      p {
        font-family: "Metropolis";
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.02em;
        color: #2e2e2e;
      }
    }
  }
}
