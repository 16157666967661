@import "../../../colors/colors.scss";
.ConfirmVerify {
  position: relative;
  background-color: #c5d7d7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-x: hidden;
  padding: 40px 0px;
  box-sizing: border-box;
  .innerDiv {
    width: 100%;
    height: 85vh;
    min-height: 600px;
    background-color: white;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
      font-family: "Metropolis";
      font-weight: 700;
      font-size: 32px;
      color: $primary;
      margin-bottom: 25px;
      text-align: center;
    }
    h6 {
      width: 50%;
      font-family: "Lato";
      font-weight: 500;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.75);
      text-align: center;
      margin-bottom: 25px;
    }
    .btnDiv {
      width: 25%;
      margin-bottom: 15px;
      min-width: 200px;
    }
    .resendEmail {
      display: flex;
      p {
        font-family: "Lato";
        font-weight: 600;
        font-size: 16px;
        color: #000000;
        margin-right: 4px;
        span {
          cursor: pointer;
        }
      }
      .timer {
        span {
          font-family: "Lato";
          font-weight: 600;
          font-size: 16px;
          color: #000000;
        }
      }
    }
  }
  .gradient {
    box-sizing: border-box;
    position: absolute;
    width: 598px;
    height: 598px;
    border: 150px solid rgba(4, 40, 38, 0.025);
    border-radius: 50%;
    top: -25%;
    right: -8%;
  }
  .secondGradient {
    box-sizing: border-box;
    position: absolute;
    width: 726px;
    height: 726px;
    border-radius: 50%;
    border: 150px solid rgba(4, 40, 38, 0.025);
    bottom: -30%;
    left: -10%;
  }
}

@media only screen and (max-width: 1200px) {
  .gradient {
    display: none;
  }
  .secondGradient {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .ConfirmVerify {
    .innerDiv {
      height: 70vh;
      h1 {
        font-size: 28px;
      }
      h6 {
        width: 60%;
        font-size: 16px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .ConfirmVerify {
    .innerDiv {
      height: 60vh;
      width: 90%;
      margin: auto;
      h1 {
        margin-bottom: 10px;
      }
      h6 {
        width: 70%;
        margin-bottom: 10px;
      }
    }
  }
}
