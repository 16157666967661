@import "../../../colors/colors.scss";
.deleteEmployee {
  width: 80%;
  height: 250px;
  box-sizing: border-box;
  margin: 0px auto;
  position: relative;
  h1 {
    text-align: center;
    font-family: "Lato";
    font-weight: 600;
    font-size: 22px;
    color: #093431;
    padding: 60px 0px 10px 0px;
  }
  .btnDelete {
    width: 30%;
    margin: 30px auto 0px auto;
  }
  .exit {
    position: absolute;
    top: 7%;
    right: -8%;
    cursor: pointer;
  }
}
