@import "../../../colors/colors.scss";
.editorDiv {
  width: 45%;
  .titleDiv {
    position: relative;
    .forErrors {
      color: red;
      position: absolute;
      top: -30%;
      right: 1%;
      font-size: 15px;
      font-family: "Metropolis";
      font-weight: 600;
    }
  }
  .inputTitle {
    position: relative;
    .forErrors {
      color: red;
      position: absolute;
      top: -30%;
      right: 1%;
      font-size: 15px;
      font-family: "Metropolis";
      font-weight: 600;
    }
  }
  .inputStatement {
    position: relative;
    .forErrors {
      color: red;
      position: absolute;
      top: -3%;
      right: 1%;
      font-size: 15px;
      font-family: "Metropolis";
      font-weight: 600;
    }
  }
  h5 {
    font-family: "Metropolis";
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    line-height: 20px;
    span {
      color: rgb(4, 40, 38);
      cursor: pointer;
    }
  }
  .mcqsOptions {
    h4 {
      font-family: "Metropolis";
      font-weight: 500;
      font-size: 20px;
      color: #012824;
      margin: 0px 0px 20px 40px;
    }
    .optionsDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0px;
      .radiooptions {
        width: 25px;
        height: 25px;
        color: black;
        accent-color: #012824;
      }
      .optionsInput {
        width: 85%;
        margin: 0px 20px;
      }
      img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
    .buttonsDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      padding: 0px 20px;
      .btnAddOption {
        width: 40%;
      }
      .btnSave {
        width: 35%;
      }
    }
  }
}
