* {
  margin: 0;
  padding: 0;
}
@import "../../../colors/colors.scss";
.Price {
  background-color: #f8f8f8;
  padding: 90px 0px;
  .priceHeading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    h1 {
      font-size: 36px;
      font-weight: 700;
      line-height: 38px;
      font-family: "Lato";
    }
    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      font-family: "Metropolis";
      padding-top: 10px;
    }
    .switch {
      display: flex;
      align-items: center;
      padding: 30px 0px;
      p {
        padding: 0;
      }
      .switchBtn {
        margin: 0px 10px;
      }
    }
  }
  .planCards {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .compare {
    display: flex;
    justify-content: center;
    flex-direction: column;
    h2 {
      font-family: "Metropolis";
      font-weight: 700;
      font-size: 28px;
      line-height: 50px;
      text-align: center;
      padding: 50px 0px 70px 0px;
    }
    .packages {
      background: #ffffff;
      box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
      border-radius: 15px;
      h5 {
        font-weight: 500;
        font-size: 16px;
        color: #042826;
        font-family: "Lato";
      }
      .iIconDiv {
        display: flex;
        align-items: center;
        p {
          font-family: "Metropolis";
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.01em;
          color: rgba(0, 0, 0, 0.6);
          margin-right: 5px;
        }
        img {
          cursor: pointer;
        }
      }
      .grid {
        display: grid;
        grid-template-columns: 50% auto auto auto;
        grid-auto-rows: 60px;
        margin-left: 40px;
        align-items: center;
      }
      .mobGrid {
        display: none;
      }
      .btnGrid {
        display: grid;
        grid-template-columns: 52% auto 16.5% 18%;
        align-items: center;
        grid-auto-rows: 70px;
      }
      .mobBtn {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  .Price {
    .compare {
      .packages {
        .btnGrid {
          grid-template-columns: 52% auto 16% 19%;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .Price {
    .compare {
      .packages {
        .btnGrid {
          grid-template-columns: 52% auto 15.5% 20%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Price {
    .priceHeading {
      h1 {
        font-size: 22px;
        line-height: 23px;
      }
      p {
        font-size: 18px;
        line-height: 22px;
        padding: 4 0px 0px 20px 0px;
      }
      .switch {
        p {
          font-size: 16px;
          line-height: 18px;
        }
        .switchBtn {
          margin: 0px 30px;
        }
      }
    }
    .compare {
      h2 {
        font-size: 18px;
        line-height: 50px;
        padding: 40px 0px 50px 0px;
      }
      .packages {
        h5 {
          font-size: 13px;
          line-height: 50px;
        }
        .iIconDiv {
          p {
            font-size: 14px;
            line-height: 17px;
          }
        }
        .grid {
          margin-left: 15px;
          grid-template-columns: 60% auto auto auto;
          img {
            width: 19px;
            height: 20px;
          }
        }
        .tabGrid {
          display: none;
        }
        .mobGrid {
          display: grid;
          grid-template-columns: 59% auto auto auto;
          grid-auto-rows: 60px;
          margin-left: 15px;
          align-items: center;
        }
        .btnGrid {
          display: none;
        }
        .mobBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0px;
        }
      }
    }
  }
}
