@import "../../../colors/colors.scss";
.AttachLink {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
  flex-direction: column;
  padding: 300px 0px;
  p {
    font-size: 20px;
    line-height: 47px;
    color: #012824;
    font-family: "Metropolis";
  }
  .pasteLink {
    width: 40%;
    margin: 50px 0px 80px 0px;
  }
  .btnNext {
    width: 20%;
  }
}
