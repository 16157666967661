@import "../../../colors/colors.scss";
label {
  position: relative;
  width: 100%;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.75);
  font-family: "Lato";
}

.forIcon {
  position: relative;
  width: 100%;
  cursor: pointer;
  .inputfieldIcon {
    position: absolute;
    top: 34%;
    right: 6%;
  }

  input {
    &.light {
      color: black;
      background-color: transparent;
      border-radius: 5px;
      width: 100%;
      border: 2px solid rgba(0, 0, 0, 0.25);
      outline: none;
      padding: 15px 15px;
      box-sizing: border-box;
      font-family: "Lato";
      &.error {
        border: 2px solid red;
      }
    }
    &.blank {
      color: black;
      background-color: transparent;
      border-radius: 5px;
      outline: none;
      border: 1.5px solid rgba(0, 0, 0, 0.15);
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
      margin: 5px 0px 0px 0px;
      font-family: "Lato";
      &.error {
        border: 0.5px solid red;
      }
    }
    &.dull {
      color: white;
      background: #021b19;
      border-radius: 5px;
      width: 100%;
      border: none;
      outline: none;
      padding: 13px 15px;
      box-sizing: border-box;
      font-family: "Lato";
    }
    &.white {
      color: black;
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      width: 100%;
      outline: none;
      padding: 15px 15px;
      box-sizing: border-box;
      font-family: "Lato";
      &.white::placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
      &.error {
        border: 1px solid red;
      }
    }
    &.dotted {
      border: 2px dashed rgba(4, 40, 38, 0.5);
      border-radius: 65px;
      background: rgba(229, 238, 237, 0.75);
      width: 100%;
      outline: none;
      padding: 20px 30px;
      box-sizing: border-box;
      font-family: "Metropolis";
      font-size: 20px;
      &.dotted::placeholder {
        color: rgba(0, 0, 0, 0.25);
        font-size: 20px;
        line-height: 47px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  input {
    &.light {
      margin-top: 20px;
    }
  }
}
