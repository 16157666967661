@import "../../../colors/colors.scss";
.addDiv {
  background-color: #f8f8f8;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 200px;
  .btnDiv {
    display: none;
  }
  .remainingProgressDiv {
    width: 90%;
    margin: 0px auto;
  }
  .addAssessment {
    box-sizing: border-box;
    width: 350px;
    margin: 0px auto;
    .inputDiv {
      position: relative;
      width: 100%;
      margin: 70px 0px 20px 0px;
      font-family: "Metropolis";
      .forErrors {
        color: red;
        position: absolute;
        font-size: 14px;
        font-family: "Lato";
        font-weight: 600;
        right: 1%;
        top: -6%;
      }
    }
    .dropdownDiv {
      margin-bottom: 20px;
      position: relative;
      .forErrors {
        color: red;
        position: absolute;
        font-size: 14px;
        font-family: "Lato";
        font-weight: 600;
        right: 1%;
        top: -6%;
      }
      p {
        font-size: 15px;
        color: rgba(0, 0, 0, 0.75);
        font-family: "Metropolis";
        margin-bottom: 3px;
      }
    }
    .btnNext {
      margin-top: 50px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .addDiv {
    .btnDiv {
      display: block;
      margin: 60px 0px 0px 0px;
      display: flex;
      justify-content: center;
    }
  }
}
