@import "../../../colors/colors.scss";
.CandidatesDashboard {
  background-color: #f8f8f8;
  padding-bottom: 200px;
  .dashBtn {
    display: none;
  }
  .innerDiv {
    padding: 0px 60px;
    .CandidatesResults {
      display: flex;
      justify-content: center;
      padding-top: 60px;
      filter: drop-shadow(0px 12px 24px rgba(134, 134, 134, 0.08));
      flex-wrap: wrap;
      margin-bottom: 25px;
      .result {
        width: 280px;
        height: 135px;
        background-color: white;
        border-radius: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 10px 20px;
        flex-shrink: 0;
        .Circle {
          background-color: rgba(248, 215, 218, 1);
          border-radius: 50%;
          width: 68px;
          height: 68px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0px 30px;
          &.green {
            background-color: rgba(209, 231, 221, 1);
          }
          &.peach {
            background-color: rgba(255, 243, 205, 1);
          }
          &.grey {
            background-color: rgba(242, 244, 244, 1);
          }
          h5 {
            font-family: "Metropolis";
            font-weight: 400;
            font-size: 36px;
            color: #000000;
          }
        }
        p {
          font-family: "Metropolis";
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
        }
      }
    }
    .inputfieldDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 60px 0px;
      .inputDiv {
        width: 40%;
      }
      .assessmentDropdown {
        width: 25%;
      }
    }
    .pageinationDiv {
      padding: 40px 0px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .CandidatesDashboard {
    .dashBtn {
      display: flex;
      justify-content: center;
      margin-top: 60px;
    }
    .innerDiv {
      .inputfieldDiv {
        flex-direction: column;
        .inputDiv {
          width: 100%;
          margin-bottom: 20px;
        }
        .assessmentDropdown {
          width: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .CandidatesDashboard {
    .innerDiv {
      .CandidatesResults {
        .result {
          width: 250px;
          height: 105px;
          .Circle {
            width: 48px;
            height: 48px;
            h5 {
              font-size: 28px;
            }
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
  .white{
    width: 100% !important;
  }
}
