@import "../../../colors/colors.scss";
.FeitL {
  padding-left: 8px;
}
.score {
  width: 65px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  border-radius: 20px;
  margin-right: 5px;
  p {
    color: white;
    font-size: 14px;
    font-family: "Metropolis";
  }
}
.grade {
  width: 65px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f4f4;
  border-radius: 8px;
  p {
    color: $primary;
    font-size: 14px;
    font-family: "Metropolis";
  }
}

.detailBtn {
  font-family: "Metropolis";
    font-weight: 500;
    font-size: 14px;
    text-decoration-line: underline;
    color: #3f5c59;
    cursor: pointer;

}
