/* .card-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    background-color: darkgray;
}
.reverse-wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row-reverse;
    background-color: darkgray;
} */

.card-left {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
}
.ui-img {
  width: 30%;
}

/* .card {
    width: 450px;
    height: 289.15px;
    background: #E5EEED;
    border-radius: 15px;
} */

.card-right {
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-parent {
  display: flex;
}

.circle-img {
  width: 50px;
  height: 50px;
}

.circle-heading {
  padding: 0px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;

  color: #000000;
}

.circle-parah {
  padding: 0px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.65);
  width: 335px;
}

@media (max-width: 960px) {
  .card-wrapper {
    flex-direction: column;
  }

  .card-left {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }

  .card-right {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    height: 250px;
  }
}

@media (max-width: 760px) {
  .circle-heading {
    font-size: 16px;
    padding: 0px 0px 0px 10px;
    width: 100%;
  }
  .circle-parent {
    width: 100%;
  }
  .circle-parah {
    font-size: 16px;
    padding: 0px 0px 0px 10px;
    width: 100%;
  }
}
