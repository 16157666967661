@import "../../../colors/colors.scss";
.AssessmentsDashboard {
  background-color: #f8f8f8;
  .innerDiv {
    padding: 0px 60px;
    .btnDiv {
      display: none;
    }
    .Fieldbtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 60px 0px;
      .fieldDiv {
        width: 40%;
      }
    }
    .pageinationDiv {
      padding: 50px 0px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .AssessmentsDashboard {
    .innerDiv {
      .btnDiv {
        display: block;
        margin: 60px 0px 0px 0px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .AssessmentsDashboard {
    .innerDiv {
      .Fieldbtn {
        display: block !important;
        .fieldDiv {
          width: 100% !important;
          margin-bottom: 15px;
        }
      }
    }
  }
}
