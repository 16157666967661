@import "../../../colors/colors.scss";
.Assessment {
  background-color: #f8f8f8;
  padding: 50px 0px 100px 0px;
  .btnDiv {
    display: none;
  }
  .innerDiv {
    padding: 0px 60px;
    .previousPage {
      img {
        cursor: pointer;
      }
    }
    .pageinationDiv {
      margin: 30px 0px 70px 0px;
    }
    .btnDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 50px;
      margin-bottom: 20px;
      h3 {
        font-family: "Metropolis";
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.75);
      }
    }
    .testTableDiv {
      padding: 50px 0px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .Assessment {
    padding: 0px 0px 100px 0px;
    .btnDiv {
      display: flex;
      justify-content: center;
      margin-top: 60px;
    }
  }
}
