@import "../../../colors/colors.scss";
.white {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.black {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}
.Blight {
  border: 1px solid rgba(4, 40, 38, 0.1);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}
.smallBlack {
  width: 210px;
  border: 1px solid rgba(1, 40, 36, 1);
  border-radius: 10px;
  margin: 16px 0px;
}
.smallWhite {
  width: 210px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  margin: 16px 0px;
}
.dashed {
  width: 320px;
  height: 0px;
  border: 1px dashed #a9a9aa;
}
.noShadow {
  border: 1px solid rgba(1, 40, 36, 0.1);
}
.progress {
  width: 230px;
  border-top: 1.5px solid rgba(4, 40, 38, 0.25);
}
.currentActive {
  width: 190px;
  border-top: 2px solid $primary;
}
.vertical {
  height: 37px;
  border-left: 1px solid $primary;
  border-right: 1px solid $primary;
}

@media only screen and (max-width: 768px) {
  .smallWhite {
    width: 135px;
  }
  .smallBlack {
    width: 135px;
  }
}
