@import "../../../colors/colors.scss";
.candidateProgress {
  display: flex;
  align-items: center;
  p {
    font-family: "Metropolis";
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.45);
  }
  .indegator {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 10px;
    &.yellow {
      background-color: #ffe07d;
    }
    &.green {
      background: rgba(37, 141, 92, 0.75);
    }
    &.red {
      background: rgba(222, 64, 78, 0.75);
    }
  }
}
.CandidatesStatus {
  width: 187px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.yellow {
    background: #fff3cd;
  }
  &.green {
    background: #d1e7dd;
  }
  &.red {
    background: #f8d7da;
  }
  p {
    text-transform: uppercase;
    font-family: "Metropolis";
    font-weight: 500;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
  }
}
.candidateDetails {
  cursor: pointer;
}
