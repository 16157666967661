@import "../../../../colors/colors.scss";
.organizationForm {
  text-align: center;
  .darkLogo {
    padding: 85px 0px 40px 0px;
    margin: 0px 100px;
    z-index: 40;
    img {
      cursor: pointer;
    }
  }
  p {
    font-family: "Metropolis";
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: $primary;
    margin-bottom: 40px;
  }
  .textfield {
    text-align: left;
    width: 100%;
    position: relative;
    .forErrors {
      position: absolute;
      color: red;
      font-size: 16px;
      font-weight: 600;
      font-family: "Lato";
      right: 2%;
      top: 4%;
    }
  }
  .dropdownDiv {
    margin: 20px 0px 0px 0px;
    .css-1u9des2-indicatorSeparator {
      display: none;
    }
    .css-tj5bde-Svg {
      fill: black;
      opacity: 0.45;
    }
    h6 {
      margin: 0px 0px 6px 0px;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.75);
      text-align: left;
      font-family: "Metropolis";
    }
  }
  .btnNext {
    margin: 40px 0px 0px 0px;
  }
}

@media only screen and (max-width: 1200px) {
  .organizationForm {
    width: 100%;
    .darkLogo {
      padding: 50px 0px 20px 0px;
      margin: 0px 50px;
      img {
        width: 85px;
        height: 35px;
      }
    }
    p {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

@media only screen and (max-width: 552px) {
  .organizationForm {
    p {
      font-size: 14px;
    }
  }
}
