.footer-wrapper {
    width: 100%;
    height: 469px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #042826;
}

.footer-input-wrapper {
    width: 90%;
    height: 296px;
    display: flex;
}

.first-div {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding-right:10px;
}

.icon-div {
    display: flex;
    gap: 20px;
}

.snd-text-div {
    padding-left:10px;
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.text-heading {
    color: #FFF;
    font-size: 15px;
    font-family: Lato;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
}

.ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.sign-up-div {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.sign-up-text {
    color: rgba(255, 255, 255, 0.80);
    font-size: 16px;
    font-family: Lato;
}

.email-input {
    width: 100%;
    height: 34px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #021B19;
    border: none;
    outline: none;
    padding-left: 10px;
    color: white;
}

.submit-btn {
    width: 100%;
    height: 44px;
    border-radius: 5px;
    background: #FDF5D1;
    color: #012824;
    text-align: center;
    font-size: 18px;
    font-family: Newake;
    letter-spacing: 0.54px;
}
@media (max-width:768px){
    .footer-wrapper {
        height: 1000px;
    }
    .footer-input-wrapper {
        flex-direction: column;
        gap: 30px;
        height: auto;
    }
    .first-div {
        width:100%;
    }
    .snd-text-div {
        width: 100%;
        text-align: center;
    }
    .sign-up-div {
        width: 100%;
    }
}