@import "../../colors/colors.scss";
.Congratulation {
  position: relative;
  background-color: #c5d7d7;
  padding: 105px 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  .innerDiv {
    padding: 230px 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    h1 {
      font-size: 36px;
      font-family: "Lato";
    }
    .profileDiv {
      display: flex;
      align-items: center;
      margin: 30px 0px;
      p {
        font-family: "Metropolis";
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.03em;
        color: rgba(0, 0, 0, 0.55);
        margin-left: 10px;
      }
    }
    h5 {
      font-family: "Metropolis";
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.75);
      margin-bottom: 50px;
    }
  }
  .Circle {
    box-sizing: border-box;
    position: absolute;
    width: 726px;
    height: 726px;
    border: 150px solid rgba(4, 40, 38, 0.025);
    border-radius: 50%;
    bottom: -35%;
    left: -13%;
  }
  .CircleTwo {
    box-sizing: border-box;
    position: absolute;
    width: 598px;
    height: 598px;
    border: 150px solid rgba(4, 40, 38, 0.025);
    border-radius: 50%;
    top: -26%;
    right: -10%;
  }
}

@media only screen and (max-width: 1200px) {
  .Congratulation {
    .Circle {
      display: none;
    }
    .CircleTwo {
      display: none;
    }
  }
}

@media only screen and (max-width: 992px) {
  .Congratulation {
    padding: 160px 100px;
    .innerDiv {
      padding: 150px 30px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .Congratulation {
    padding: 160px 100px;
    .innerDiv {
      padding: 150px 40px;
      h1 {
        font-size: 24px;
      }
      .profileDiv {
        flex-direction: column;
        img {
          width: 150px;
        }
        p {
          font-size: 16px;
          line-height: 18px;
        }
      }
      h5 {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
