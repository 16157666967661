@import "../../../colors/colors.scss";
.Invite {
  background-color: #fdf5d1;
  text-align: center;
  .container {
    .hiringImage {
      margin-bottom: -5px;
    }
  }
  .inviteHeading {
    display: flex;
    justify-content: center;
    h1 {
      font-family: "Metropolis";
      font-weight: 700;
      font-size: 34px;
      line-height: 50px;
      letter-spacing: 0.5px;
      padding: 80px 0px 30px 0px;
      margin-right: 5px;
    }
  }
  .mobText {
    display: none;
  }
  p {
    font-family: "Metropolis";
    font-weight: 500;
    font-size: 18px;
    line-height: 125%;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    padding-bottom: 40px;
  }
  img {
    margin-right: 90px;
  }
}

@media only screen and (max-width: 1200px) {
  .Invite {
    text-align: center;
    .inviteHeading {
      padding: 80px 0px 30px 0px;
      flex-direction: column;
      h1 {
        padding: 0;
        font-size: 22px;
        line-height: 24px;
        margin: 0;
      }
    }
    .dextText {
      display: none;
    }
    .mobText {
      display: block;
    }
    p {
      font-size: 15px;
      line-height: 19px;
    }
    img {
      width: 346px;
      height: 387px;
      margin: 50px 0px 0px 0px;
    }
  }
}
