@import "../../../colors/colors.scss";
.uploadFiles {
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 200px 0px;
  .whiteDiv {
    width: 642px;
    height: 423px;
    background: #ffffff;
    box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    .dashedBorder {
      border: 2px dashed rgba(4, 40, 38, 0.5);
      border-radius: 25px;
      width: 610px;
      height: 389px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        margin-bottom: 10px;
      }
      h6 {
        font-family: "Metropolis";
        font-size: 20px;
        line-height: 40px;
        color: #012824;
      }
      h5 {
        font-family: "Metropolis";
        font-size: 18px;
        line-height: 20px;
        color: rgba(1, 40, 36, 0.75);
      }
      .OR {
        display: flex;
        align-items: center;
        margin: 10px 0px;
        p {
          font-family: "Metropolis";
          font-size: 20px;
          line-height: 23px;
          color: rgba(0, 0, 0, 0.35);
          margin: 0px 5px;
        }
        .verticalLine {
          width: 115px;
          border: 1px solid rgba(4, 40, 38, 0.1);
        }
      }
      //   .btnBrowse {
      //     margin-top: 10px;
      //   }
    }
  }
  .btnNext {
    margin-top: 60px;
    width: 20%;
  }
}
