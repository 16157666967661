@import "../../../colors/colors.scss";
.buildTeam {
  background-color: #fdf5d1;

  padding: 0px 200px 110px;

  h2 {
    color: $primary;
    text-align: center;
    font-family: "Lato";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 105%; /* 37.8px */
    margin-top: 98px;
    margin-bottom: 153px;
  }

  .mainDiv {
    display: flex;
    flex-direction: column;
    padding-bottom: 90px;
    .mainDiv-heading-container {
      display: flex;
      justify-content: center;
    }
    .mainDiv-heading {
      display: flex;
      justify-content: center;
    }
    .images {
      position: relative;
      .thinking-woman {
        position: absolute;
        left: -28%;
        top: -17%;
      }
    }
    .btn-div {
      margin-left: 70px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      .build-team-p1 {
        color: $primary;
        font-family: Lato;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }

      .btnLearn {
        margin-top: 40px;
      }
    }
  }
}

// @media only screen and (max-width: 1400px) {
//   .buildTeam {
//     .mainDiv {
//       .images {
//         .thinking-woman {
//           width: 311px;
//           height: 356px;
//           left: -7%;
//           top: -2%;
//         }
//       }
//     }
//   }
// }

// @media only screen and (max-width: 1200px) {
//   .buildTeam {
//     padding: 60px 0px 52px 0px;
//     h2 {
//       font-size: 30px;
//       line-height: 31px;
//     }
//     p {
//       display: block;
//       font-family: "Metropolis";
//       font-weight: 400;
//       font-size: 16px;
//       line-height: 22px;
//       text-align: center;
//       color: $primary;
//       margin-top: 25px;
//     }
//     .mainDiv {
//       .images {
//         .thinking-woman {
//           width: 311px;
//           height: 356px;
//           left: -26%;
//           top: 21%;
//         }
//       }
//     }
//     .btn-div {
//       display: none;
//     }
//   }
// }

// @media only screen and (max-width: 992px) {
//   .buildTeam {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding: 60px 0px 100px 0px;
//     .tabText {
//       display: none;
//     }
//     .mobText {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//     }
//     h2 {
//       font-size: 22px;
//       line-height: 24px;
//       margin: 0;
//     }
//     p {
//       font-size: 15px;
//       line-height: 17px;
//       margin-top: 20px;
//       text-align: left;
//     }
//     .btnStart {
//       display: flex;
//       justify-content: center;
//       margin-top: 35px;
//     }
//     .mainDiv {
//       padding-top: 35px;
//       .images {
//         margin: 0;
//         .table {
//           width: 330px;
//           height: 180px;
//         }
//         .thinking-woman {
//           width: 311px;
//           height: 356px;
//           left: -1%;
//           top: 9%;
//         }
//       }
//     }
//   }
// }
