.DropdownSelect {
  position: relative;
  #profession {
    width: 100%;
    height: 43px;
    border-radius: 5px;
    padding: 0px 10px;
    outline: none;
    border: 1.5px solid rgba(0, 0, 0, 0.15);
    .options {
      background-color: white;
      color: black;
    }
    &.error {
      border: 1.5px solid red;
    }
  }
  p {
    position: absolute;
    color: red;
    right: 0%;
    top: -52%;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    font-family: "Lato";
  }
}
