@import "../../../colors/colors.scss";

.AssessmentWrapper {
  width: 100%;
  .FirstAssessment {
    display: flex;
    background-color: #f8f8f8;
    text-align: center;
    .logoContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 60px 0px 40px 0px;
      // }
    }
    .timerDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 22px;
        height: 25px;
        margin: 0;
      }
      span {
        font-family: "Metropolis";
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: #012824;
      }
    }
    progress {
      width: 100%;
      accent-color: rgba(1, 40, 36, 1);
    }
    .testContainer {
      margin: 30px 0px;
      .testRemaining {
        margin: 35px 0px 15px 0px;
        p {
          font-family: "Metropolis";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 18px;
          color: #000000;
          text-align: left;
        }
      }
      .questionsDiv {
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        .questionNumber {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 25px 30px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.25);
          p {
            font-family: "Metropolis";
            font-weight: 500;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.75);
          }
        }
        .question {
          padding: 30px 100px 25px 30px;
          text-align: left;
          p {
            font-family: "Metropolis";
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: rgba(0, 0, 0, 0.65);
            user-select: none;
          }
        }
      }
      h6 {
        font-family: "Metropolis";
        font-weight: 500;
        font-size: 18px;
        line-height: 17px;
        color: #000000;
        text-align: left;
        padding: 50px 0px 10px 63px;
      }

      .optionsDiv {
        width: 40%;
        .selector {
          display: flex;
          width: 100%;
          input {
            width: 40px;
            accent-color: #012824;
            cursor: pointer;
          }
          .mcqs {
            width: 100%;
            height: 54px;
            border: 1px solid rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0px 30px;
            margin: 10px 0px 10px 30px;
            text-align: left;
            p {
              font-family: "Metropolis";
              font-weight: 500;
              font-size: 16px;
              color: rgba(0, 0, 0, 0.5);
              user-select: none;
            }
          }
        }
      }
    }

    .btnNext {
      display: flex;
      justify-content: end;
      margin-left: auto;
      padding-bottom: 30px;
    }
  }
}

@media screen and (max-width: 820px) {
  .AssessmentWrapper {
    .FirstAssessment {
      padding: 0px 10px 0px 10px;
      .testContainer {
        .optionsDiv {
          width: auto;
          .selector {
            .mcqs {
              height: 80px;
              .p {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
