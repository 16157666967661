.main {
  width: 100%;
  background: #f8f8f8;
}
.wrapper {
  width: 100%;
  height: 90px;
  background-color: black;
}

.sec-two-card {
  display: flex;
  flex-direction: row-reverse;
}

.wrapper {
  width: 100%;
  height: auto;
  display: flex;
  background-color: darkgray;
}

.reverse-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row-reverse;
  background-color: darkgray;
}

@media (max-width: 960px) {
  .wrapper,
  .reverse-wrapper {
    flex-direction: column;
  }
}
