@import "../../../colors/colors.scss";
.card {
  display: flex;
  flex-direction: column;
  background-color: rgba(1, 28, 26, 1);
  padding: 6px 6px 0px 6px;
  border-radius: 25px;
  align-items: center;
  align-self: center;

  .staffImg {
    object-fit: cover;
  }

  .name-div {
    display: flex;
    padding-top: 22px;
    padding-bottom: 17px;
    margin-left: 24px;
    margin-right: 23px;
    align-items: center;
    gap: 23px;

    .info {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }
    .name {
      color: #fff;
      height: 19px;
      font-family: "Metropolis";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 115%;
    }
    .position {
      color: rgba(255, 255, 255, 0.65);
      height: 15px;
      font-family: "Metropolis";
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 115%;
    }
  }
  .img-div {
    display: flex;
    align-items: center;
    margin-top: 17px;
    .vector {
      margin-top: 5px;
      width: 16px;
      height: 16px;
    }
    p {
      color: #00ff85;
      font-size: 16px;
      margin: 0;
      margin-left: 5px;
      font-family: "Metropolis";
      margin-top: 8px;
    }
    .mobText {
      display: none;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .card {
    height: 315px;
    width: 255px;

    img {
      width: 260px;
      height: 250px;
    }
    .name-div {
      h5 {
        font-size: 15px;
        line-height: 17px;
      }
      h6 {
        font-size: 12px;
        line-height: 13px;
        padding-top: 5px;
      }
      p {
        font-size: 13px;
        line-height: 14px;
        margin-left: 0;
      }
    }
    .img-div {
      margin-top: 5px;
      .vector {
        width: 16px;
        height: 15px;
        margin-right: 5px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .card {
    width: 250px;
    height: 310px;
    border-radius: 20px 20px 5px 5px;
    .cardImg {
      margin-top: 15px;
    }
    img {
      width: 250px;
      height: 245px;
    }
    .name-div {
      margin: 0;
      h5 {
        font-size: 14px;
        line-height: 16px;
      }
      h6 {
        font-size: 13px;
        line-height: 14px;
        margin-bottom: 5px;
      }
      p {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 5px;
      }
    }
    .img-div {
      flex-direction: column;
      margin-bottom: 10px;
      img {
        width: 17px;
        height: 17px;
        margin-left: 10px;
      }
      .mobText {
        display: block;
        p {
          font-weight: 600;
          font-size: 14px;
          margin-top: 2px;
        }
      }
      .text {
        display: none;
      }
    }
    .img-div {
      .vector {
        width: 17px;
        height: 17px;
        margin-top: 10px;
      }
    }
  }
}
