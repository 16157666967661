.earlyAccessWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 160px 0 160px 0;
  background-color: #e7eded;

  .earlyAccessImage {
    object-fit: cover;
  }
  .earlyAccessContent {
    display: flex;
    flex-direction: column;
    gap: 31px;
    .description {
      width: 574px;
      color: #082725;
      font-family: "Lato";
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .earlyAccessBtn {
      display: flex;
    }
  }
}
