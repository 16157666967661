* {
  margin: 0;
  padding: 0;
}

.Branding {
  text-align: center;
  margin-top: 40px;
  h1 {
    font-family: "Lato";
    font-weight: 600;
    font-size: 40px;
    line-height: 50px;
    color: #ffffff;
    text-align: left;
  }
  img {
    width: 450px;
    height: 450px;
  }
}
