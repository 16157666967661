@import "../../../colors/colors.scss";
.candidateNameAndEmail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
  height: 84px;
  padding: 0px 10px 0px 40px;
  box-sizing: border-box;
  p {
    font-family: "Metropolis";
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;
    color: rgba(0, 0, 0, 0.6);
  }
  h5 {
    font-family: "Metropolis";
    font-weight: 500;
    font-size: 17px;
    line-height: 16px;
    color: #012824;
  }
  .nameDiv {
    p {
      margin-bottom: 2px;
    }
    .emailDiv {
      display: flex;
      align-items: center;
      h5 {
        font-family: "Metropolis";
        font-weight: 500;
        font-size: 22px;
        line-height: 21px;
        color: #012824;
      }
      p {
        font-family: "Metropolis";
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: rgba(1, 40, 36, 0.8);
        margin-left: 10px;
      }
    }
  }
  .iconsDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0.4;
    cursor: not-allowed;
    img {
      margin: 0px 15px;
      // cursor: pointer;
    }
  }
}

