@import "../../../colors/colors.scss";
.assessCard {
  width: 335px;
  height: 360px;
  background-color: white;
  box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0px 20px 20px 20px;
  box-sizing: border-box;
  h6 {
    font-family: "Lato";
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    color: #012824;
    padding: 20px 0px;
  }
  p {
    font-family: "Metropolis";
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #012824;
    width: 50%;
  }
}

@media only screen and (max-width: 1200px) {
  .assessCard {
    width: 300px;
    height: 310px;
    img {
      width: 70px;
      height: 70px;
    }
    h6 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
  }
}
