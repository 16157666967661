* {
  margin: 0;
  padding: 0;
}
@import "../../../colors/colors.scss";
.loginpage {
  position: relative;
  display: flex;
  justify-content: center;
  .Login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      margin: 0px 0px 40px 0px;
      font-family: "Lato";
      font-size: 36px;
      line-height: 42px;
      font-weight: 600;
    }
    .google {
      width: 100%;
      img {
        margin-right: 10px;
      }
      p {
        font-size: 20px;
        line-height: 23px;
        color: #1976d2;
      }
    }
    .or {
      display: flex;
      align-items: center;
      margin: 25px 5px 0px 5px;
      .line {
        width: 170px;
        height: 0px;
        border-top: 1px solid rgba(4, 40, 38, 0.1);
      }
      p {
        color: rgba(0, 0, 0, 0.35);
        font-family: "Metropolis";
        margin: 0px 5px;
      }
    }
    Form {
      width: 100%;
      text-align: center;
    }
    .inputDiv {
      position: relative;
      width: 100%;
      margin-top: 25px;
      .forErrors {
        position: absolute;
        color: red;
        font-size: 14px;
        font-weight: 600;
        font-family: "Lato";
        left: 0%;
        bottom: -32%;
      }
    }

    h6 {
      font-family: "Metropolis";
      font-size: 15px;
      line-height: 21px;
      color: $primary;
      margin: 20px 0px 0px 0px;
    }
    .rememberMe {
      display: flex;
      align-items: center;
      text-align: left;
      margin: 20px 0px 10px 0px;
      .inputCheckbox {
        width: 25px;
        height: 18px;
        accent-color: $primary;
      }
      p {
        font-family: "Metropolis";
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.02em;
        color: #012824;
        margin-left: 15px;
      }
    }
    .forgetPassword {
      h5 {
        font-family: "Metropolis";
        font-size: 15px;
        line-height: 21px;
        color: $primary;
        width: 45%;
        margin: 10px auto 0px auto;
        cursor: pointer;
      }
    }
    .navigateToSignup {
      p {
        font-family: "Metropolis";
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.02em;
        color: #012824;
        text-decoration: none;
        margin-top: 15px;
        font-weight: 400;
        span {
          text-decoration: underline;
          font-size: 18px;
          font-weight: 600;
          color: $primary;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .loginpage {
    .Login {
      width: 400px !important;
      border-radius: 15px;
      h1 {
        font-size: 26px;
        line-height: 31px;
        margin: 10px 0px 20px 0px;
      }
      .google {
        img {
          width: 21px;
          height: 21px;
        }
        p {
          font-size: 16px;
          line-height: 20px;
        }
      }
      .or {
        margin: 10px 5px;
        .line {
          width: 183px;
        }
        p {
          font-size: 16px;
          line-height: 19px;
          margin: 0px 5px;
        }
      }
      .inputDiv {
        margin: 0;
        .forErrors {
          bottom: -25%;
          font-size: 14px;
        }
      }
      h6 {
        font-size: 15px;
        line-height: 18px;
        margin: 0;
      }
      .navigateToSignup {
        p {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 552px) {
  .loginpage {
    .Login {
      width: 330px !important;
      .or {
        .line {
          width: 140px;
        }
      }
    }
  }
}
