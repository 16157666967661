@import "../../../colors/colors.scss";
.CustomQuestions {
  background-color: #f8f8f8;
  .innerDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0px 50px 50px 50px;
    .btnDiv {
      display: none;
    }
    .remainingProgressDiv {
      align-self: center;
      width: 100%;
    }
    .testSelected {
      padding: 20px 0px;
      .modifyTest {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px 0px;
        .backArrowDiv {
          padding: 0px;
          width: 50px;
          height: 50px;
          border-radius: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #012824;
          box-sizing: border-box;
          margin-right: 10px;
          img {
            width: 18px;
            height: 18px;
            margin: 0;
          }
          .rotate {
            transform: rotate(180deg);
          }
        }
        p {
          font-family: "Metropolis";
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          width: 50%;
        }
        .unselect {
          background: rgba(197, 215, 215, 1);
          border-radius: 10px;
          width: 175px;
          height: 68px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px 15px;
          cursor: pointer;
          text-align: center;
        }
        .select {
          background: rgba(4, 40, 38, 1);
          border-radius: 10px;
          width: 175px;
          height: 68px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px 15px;
          cursor: pointer;
          text-align: center;
        }
        .arrowDiv {
          padding: 0px;
          width: 50px;
          height: 50px;
          border-radius: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #012824;
          box-sizing: border-box;
          cursor: pointer;
          margin-left: 10px;
          img {
            width: 18px;
            height: 18px;
            margin: 0;
          }
        }
      }
    }

    h2 {
      font-family: "Lato";
      font-weight: 500;
      font-size: 18px;
      color: #000000;
      margin-top: 50px;
    }
    .questionsDiv {
      display: flex;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      background-color: white;
      border-radius: 15px;
      padding: 60px;
      .questionsBox {
        border: 1px solid rgba(0, 0, 0, 0.25);
        width: 500px;
        min-width: 200px;
        max-width: 550px;
        max-height: 150px;
        box-sizing: border-box;
        border-radius: 10px;
        margin-bottom: 20px;
        overflow-y: scroll;
        cursor: pointer;
        .defaultDiv {
          border-bottom: 1px solid rgba(0, 0, 0, 0.25);
          padding: 15px 20px 10px 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .question {
            display: flex;
            img {
              margin-left: 15px;
            }
            h6 {
              font-family: "Metropolis";
              font-weight: 500;
              font-size: 18px;
              color: rgba(0, 0, 0, 0.75);
            }
          }
          .cancleQuestion {
            display: flex;
            align-items: center;
            h5 {
              color: grey;
              font-family: "Metropolis";
              font-size: 15px;
              margin-right: 10px;
            }
            img {
              width: 10px;
              cursor: pointer;
              padding: 0px 5px;
            }
          }
        }
        .statement {
          padding: 15px 20px 10px 20px;
          p {
            font-family: "Metropolis";
            font-weight: 400;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }

      .questionsBox::-webkit-scrollbar {
        display: none;
      }
      .questionsBox:hover {
        background-color: #eaf0f0;
      }
      .btnAddMore {
        width: 70%;
        margin: 30px auto 0px auto;
      }
      .editorDiv {
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: end;
        .aiBtn {
          display: flex;
          width: 50%;
          .aiBtnContent {
            width: fit-content;
            display: flex;
            align-items: center;
            .aiBtnText {
              font-size: 16px;
            }
            .aiSparkles {
              width: 20px;
              height: 20px;
              margin-left: 10px;
            }
          }
        }

        .inputTitle {
          position: relative;
          .forErrors {
            color: red;
            position: absolute;
            top: -30%;
            right: 1%;
            font-size: 15px;
            font-family: "Metropolis";
            font-weight: 600;
          }
        }
        .inputStatement {
          position: relative;
          .forErrors {
            color: red;
            position: absolute;
            top: -3%;
            right: 1%;
            font-size: 15px;
            font-family: "Metropolis";
            font-weight: 600;
          }
        }
        h5 {
          font-family: "Metropolis";
          font-weight: 600;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.5);
          text-align: center;
          line-height: 20px;
          span {
            color: rgb(4, 40, 38);
            cursor: pointer;
          }
        }
        .mcqsOptions {
          h4 {
            font-family: "Metropolis";
            font-weight: 500;
            font-size: 20px;
            color: #012824;
            margin: 0px 0px 20px 40px;
          }
          .optionsDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 15px 0px;
            .radiooptions {
              width: 25px;
              height: 25px;
              color: black;
              accent-color: #012824;
            }
            .optionsInput {
              width: 85%;
              margin: 0px 20px;
              cursor: pointer;
            }
            img {
              width: 14px;
              height: 14px;
              cursor: pointer;
            }
          }
          .buttonsDiv {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 40px;
            padding: 0px 20px;
            .btnAddOption {
              width: 40%;
            }
            .btnSave {
              width: 35%;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1400px) {
  .CustomQuestions {
    .innerDiv {
      .questionsDiv {
        .questionsBox {
          width: 400px;
          min-width: 200px;
          max-width: 550px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .CustomQuestions {
    .innerDiv {
      .btnDiv {
        display: block;
        margin: 60px 0px 0px 0px;
        display: flex;
        justify-content: center;
      }
      .questionsDiv {
        .questionsBox {
          width: 350px;
          min-width: 200px;
          max-width: 550px;
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .CustomQuestions {
    .innerDiv {
      .questionsDiv {
        flex-direction: column;
        padding: 30px;
        .questionsBox {
          width: 100%;
        }
        .editorDiv {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .CustomQuestions {
    .innerDiv {
      .questionsDiv {
        .editorDiv {
          .mcqsOptions {
            .buttonsDiv {
              flex-direction: column;
              padding: 0px;
              .btnAddOption {
                width: 100%;
              }
              .btnSave {
                width: 100%;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }
  }
}
