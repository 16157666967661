.hire-wrapper {
    width: 100%;
    height: 850px;
}

.text-div {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hire-heading {
    width: 509px;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 50px;
    letter-spacing: 0.5px;
    color: #000000;
}

.hire-text {
    width: 644px;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
}

.hire-bottom {
    width: 100%;
    height: 80%;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
}

.hire-left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hire-left-card {
    width: 450px;
    height: 308px;
    background: #FFFFFF;
    box-shadow: 0px 12px 24px rgba(134, 134, 134, 0.08);
    border-radius: 10px;
}
.hire-right {
    width:50%;
    height: 100%;
}
/* .hire-right-img {
    width: 320px;
} */
@media (max-width:768px){
    .hire-bottom {
        flex-direction: column;
    }
    .hire-left {
        width: 100%;
    }
    .hire-right{
        width: 100%;
    }
    .hire-heading {
        font-size: 22px;
        width:100%;
        text-align: center;
    }
    .hire-text {
        font-size:14px;
        text-align: center;
        width: 80%;
    }
    .hire-right-img {
        width: 100%;
    }
}