@import "../../../colors/colors.scss";
.forOwner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    margin-right: 20px;
  }
}
.role {
  width: 125px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $primary;
  border-radius: 5px;
  p {
    font-family: "Metropolis";
    font-weight: 500;
    font-size: 12px;
    color: #ffffff;
    text-transform: uppercase;
  }
  &.manager {
    background-color: rgba(203, 123, 122, 1);
  }
  &.hr {
    background-color: rgba(204, 164, 95, 1);
  }
}
.actions {
  display: flex;
  align-items: center;
  img {
    cursor: pointer;
  }
  .edit {
    margin-right: 40px;
  }
}
// .FeitL {
//   justify-content: center !important;
// }
// .eVPPvF {
//   justify-content: center !important;
// }
